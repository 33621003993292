import React from "react";
import SellingProduct from "../components/sellingProduct/SellingProduct";

const SellingProductPage = () => {
  return (
    <>
      <SellingProduct />
    </>
  );
};

export default SellingProductPage;
