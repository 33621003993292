import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { KeyboardArrowRight } from "@mui/icons-material";
import SellingCondition from "../sellingFormContainer/SellingCondition";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackbarNotifier from "../../../../shared/hooks/useSnackbarNotifier";
import {
  currentSellingProductId,
  selectedAssessmentState,
} from "../../../../../store/atoms/selling/sellingForms/sellingForms";
import { useRecoilState } from "recoil";
import transformStepToRecoilState from "../../../../../utils/transformStepToRecoilState";

const Step1All = ({ step }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productData, setProductData] = useState({
    pid: "",
    productName: "",
    productPrice: 0,
    productImage: "",
    cId: "",
    cn: "",
  });

  const productName = new URLSearchParams(location.search).get("productName");
  const productId = new URLSearchParams(location.search).get("pId");
  const productPrice = new URLSearchParams(location.search).get("productPrice");
  const imageURL = new URLSearchParams(location.search).get("imageUrl");
  const cId = new URLSearchParams(location.search).get("cId");
  const cn = new URLSearchParams(location.search).get("cn");
  let stepNo = new URLSearchParams(location.search).get("stepNo");

  useEffect(() => {
    setProductData({
      pid: productId,
      productName: productName,
      productPrice: parseInt(productPrice),
      productImage: imageURL,
      cId: cId,
      cn: cn,
    });
  }, []);

  const [selectedCondition, setSelectedCondition] = useRecoilState(
    selectedAssessmentState
  );

  const showSnackbar = useSnackbarNotifier();

  useEffect(() => {
    if (step) {
      const transformedStep = transformStepToRecoilState(step);
      setSelectedCondition((prevState) => {
        const stepExists = prevState.some(
          (existingStep) => existingStep.id === transformedStep.id
        );

        if (stepExists) {
          return prevState;
        } else {
          return [transformedStep, ...prevState];
        }
      });
    }
  }, [step]);

  const handleNavigate = () => {
    const currentStepData = selectedCondition?.find((s) => s.id === step.id);

    if (!currentStepData) {
      showSnackbar(
        "Please complete all conditions before proceeding",
        "warning"
      );
      return;
    }

    const unselectedConditions = step.conditions.filter((condition) => {
      const matchingCondition = currentStepData.conditions.find(
        (c) => c.id === condition.id
      );

      const hasSelectedOption = matchingCondition?.options?.some(
        (option) => option.isSelected
      );

      return !hasSelectedOption;
    });

    if (unselectedConditions.length > 0) {
      const unselectedTitles = unselectedConditions
        .map((condition) => condition.finalConditionName || "condition")
        .join(", ");

      showSnackbar(`Please select options for: ${unselectedTitles}`, "warning");
      return;
    }

    navigate(
      `/sell/calculator/page?pId=${productId}&productName=${productName}&productPrice=${productPrice}&imageUrl=${imageURL}&cId=${cId}&cn=${cn}&stepNo=${++stepNo}`
    );
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      sx={{
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        minHeight: "200px",
      }}
    >
      <Grid
        container
        spacing={{ xs: 6, laptop: 3 }}
        sx={{ justifyContent: "center" }}
      >
        {step?.conditions?.map((condition, index) => (
          <Grid
            key={condition.id}
            item
            xs={12}
            tabletLarge={3}
            laptop={4}
            desktop={12 / step?.conditions?.length}
          >
            <SellingCondition
              condition={condition}
              index={index}
              step={step}
              productData={productData}
            />
          </Grid>
        ))}
      </Grid>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#214C7B",
            borderRadius: "10px",
            color: "white",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#214C7B",
            },
          }}
          onClick={handleNavigate}
        >
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Next <KeyboardArrowRight />
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default Step1All;
