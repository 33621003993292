import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import { useLocation, useParams } from "react-router-dom";
import SellingBrandsSection from "./sellingBrandsSection/SellingBrandsSection";
import SellingForm from "./sellingFormSection/SellingForm";
import HomePageContainer from "../../../home/components/home/shared/HomePageContainer";
const SellingBrands = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  return (
    <ResponsiveContainer
      sx={{
        minHeight: "calc(100vh - 137px)",
        minWidth: "300px",
        boxSizing: "border-box",
        overflowX: "auto",
      }}
    >
      <Box
        margin={isSmallScreen ? "1rem 0" : "3rem 0"}
        display={"flex"}
        flexDirection={"column"}
        gap={"3rem"}
      >
        <HomePageContainer
          sx={{ margin: isSmallScreen ? "0 1rem" : "0", minWidth: "250px" }}
        >
          <SellingBrandsSection />
        </HomePageContainer>
        <HomePageContainer
          sx={{ margin: isSmallScreen ? "0 1rem" : "0", minWidth: "250px" }}
        >
          <SellingForm />
        </HomePageContainer>
      </Box>
    </ResponsiveContainer>
  );
};

export default SellingBrands;
