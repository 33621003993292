import React from "react";
import AllBrandModels from "../components/allBrandModels/AllBrandModels";

const AllBrandModelsPage = () => {
  return (
    <>
      <AllBrandModels />
    </>
  );
};

export default AllBrandModelsPage;
