import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import { useSetRecoilState } from "recoil";
import { selectedAssessmentState } from "../../../../store/atoms/selling/sellingForms/sellingForms";
import ProductQuoteCard from "./productQuote/ProductQuoteCard";
import { useQuery } from "@apollo/client";
import { QUERY_GET_SELLING_ORDER_ID } from "../../../../graphql/Query";
import { useLocation } from "react-router-dom";
import QuoteSelectedDetails from "./productQuote/QuoteSelectedDetails";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";

const SellingQuote = () => {
  const theme = useTheme();
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const setSelectedCondition = useSetRecoilState(selectedAssessmentState);
  const { data, loading, error } = useQuery(QUERY_GET_SELLING_ORDER_ID, {
    variables: {
      sellingOrderId: orderId,
    },
  });

  useEffect(() => {
    setSelectedCondition([]);
  }, []);

  if (loading) {
    return <>Loading....</>;
  }

  if (error) {
    return <>Error....</>;
  }

  return (
    <ResponsiveContainer
      sx={{
        minHeight: {
          xs: "100%",
          laptop: "60vh",
          desktop: "calc(100vh - 137px)",
        },
        minWidth: "300px",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Box
        margin={isSmallScreen ? "1rem 1rem" : "2rem 0"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={isSmallScreen ? "1rem" : "2rem"}
        sx={{ zIndex: 2 }}
      >
        <Box
          display={"flex"}
          flexDirection={isSmallScreen ? "column" : "row"}
          gap={"1rem"}
        >
          <Box flexBasis={isSmallScreen ? "100%" : "60%"}>
            <ProductQuoteCard sellingOrderData={data?.getSellingOrderById} />
          </Box>
          <Box flexBasis={isSmallScreen ? "100%" : "40%"}>
            <QuoteSelectedDetails
              sellingOrderData={data?.getSellingOrderById}
            />
          </Box>
        </Box>
      </Box>
    </ResponsiveContainer>
  );
};

export default SellingQuote;
