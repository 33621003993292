import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import swal from "sweetalert";
import CustomTypography from "../../../muicomponents/shared/CustomTypography";
import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_GET_ALL_ASSESSMENTS,
  QUERY_GET_ALL_SELLING_STEPS,
} from "../../../graphql/Query";
import { MUTATION_ADD_SELLING_STEP } from "../../../graphql/Mutations";
import SellingStepModal from "./modals/SellingStepModal";

const AddSellingStep = () => {
  const {
    data: assessmentData,
    loading: assessmentDataLoading,
    error: assessmentDataError,
  } = useQuery(QUERY_GET_ALL_ASSESSMENTS);

  const {
    data: allSellingSteps,
    loading: allSellingStepsLoading,
    error: allSellingStepsError,
  } = useQuery(QUERY_GET_ALL_SELLING_STEPS);

  // console.log(allSellingSteps);

  const [openModal, setOpenModal] = useState(false);

  const [addSellingStep, { loading: sellingStepsLoading }] = useMutation(
    MUTATION_ADD_SELLING_STEP,
    { refetchQueries: QUERY_GET_ALL_SELLING_STEPS }
  );

  const [step, setStep] = useState({
    stepName: "",
    title: "",
    finalStepTitle: "",
    subtitle: "",
    stepNumber: 0,
    description: "",
    priority: 0,
    assessment: "",
  });

  const handleAddStep = async () => {
    try {
      const finalStep = {
        stepNumber: step.stepNumber,
        finalStepTitle: step.finalStepTitle,
        title: step.title,
        subtitle: step.subtitle,
        stepName: step.stepName,
        description: step.description,
        priority: step.priority,
        assessment: step.assessment,
      };
      const { data } = await addSellingStep({
        variables: {
          sellingStep: finalStep,
        },
      });
      swal({
        title: "Success",
        text: data.addSellingStep.message,
        icon: "success",
      });
      // console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setStep({
        stepName: "",
        finalStepTitle: "",
        title: "",
        subtitle: "",
        stepNumber: 0,
        description: "",
        priority: 0,
        assessment: "",
      });
    }
  };

  const [editStep, setEditStep] = useState(null);

  const handleModalOpen = (step) => {
    setEditStep(step);
    setOpenModal(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStep({
      ...step,
      [name]:
        name === "stepNumber" || name === "priority" ? Number(value) : value,
    });
  };

  if (assessmentDataLoading) {
    return <>Loading...</>;
  }
  if (assessmentDataError) {
    return <>Error...</>;
  }
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ margin: "3rem 0", width: { xs: "100%", laptop: "inherit" } }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            width: { laptop: "425px", xs: "100%" },
            gap: "1rem",
          }}
        >
          <Box>
            <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
              Add Selling Step
            </CustomTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
              minHeight: "200px",
              backgroundColor: "white",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Name"
                variant="outlined"
                name="stepName"
                value={step.stepName}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Title"
                variant="outlined"
                name="title"
                value={step.title}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Subtitle"
                variant="outlined"
                name="subtitle"
                value={step.subtitle}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Final Step Title"
                variant="outlined"
                name="finalStepTitle"
                value={step.finalStepTitle}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Priority"
                variant="outlined"
                type={"number"}
                name="priority"
                value={step.priority === 0 ? "" : step.priority}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Number"
                type={"number"}
                variant="outlined"
                name="stepNumber"
                value={step.stepNumber === 0 ? "" : step.stepNumber}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <TextareaAutosize
                placeholder="Type Description here...."
                minRows={2}
                style={{ width: "100%", padding: "0.5rem" }}
                value={step.description}
                name={"description"}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select assessment
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="assessment"
                  value={step.assessment}
                  label="select category state"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  {assessmentData?.getAllAssessments.map((assessment) => (
                    <MenuItem key={assessment.id} value={assessment.id}>
                      {assessment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button type="submit" variant="outlined" onClick={handleAddStep}>
                Add Step
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            overflowX: "hidden",
            marginTop: "2rem",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            <TableHead className="table-head">
              <TableRow>
                <TableCell>Sl No.</TableCell>
                <TableCell>Step Name</TableCell>
                <TableCell>Step title</TableCell>
                <TableCell>Step subtitle</TableCell>
                <TableCell>Step Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Final Step Title</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Assessment Name</TableCell>

                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSellingSteps?.getAllSellingSteps.map((step, index) => (
                <TableRow key={step.id}>
                  <TableCell align="right">{index + 1}</TableCell>
                  <TableCell align="right" sx={{ wordBreak: "break-word" }}>
                    {step?.stepName}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ wordBreak: "break-word", maxWidth: "300px" }}
                  >
                    {step?.title === "" ? "N/A" : step?.title}
                  </TableCell>
                  <TableCell align="center" sx={{ textAlign: "center" }}>
                    {step?.subtitle === "" ? "N/A" : step?.title}
                  </TableCell>
                  <TableCell align="right">{step?.stepNumber}</TableCell>
                  <TableCell align="right">{step?.description}</TableCell>
                  <TableCell align="right">
                    {step?.finalStepTitle === null
                      ? "N/A"
                      : step?.finalStepTitle}
                  </TableCell>
                  <TableCell align="right">{step?.priority}</TableCell>
                  <TableCell align="right">{step?.assessment?.name}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant={"contained"}
                      onClick={() => handleModalOpen(step)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </Box>
      </Box>
      {editStep && assessmentData?.getAllAssessments && (
        <SellingStepModal
          step={editStep}
          modalOpen={openModal}
          setModalOpen={setOpenModal}
          assessments={assessmentData?.getAllAssessments}
        />
      )}
    </>
  );
};

export default AddSellingStep;
