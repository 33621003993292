import { Box, Grid, Modal, useMediaQuery, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";

const ModalBrandCard = ({ brand }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        borderRadius: "1.875rem",
        boxShadow:
          "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        alignItems: "center",
        height: { xs: "100px", laptop: "115px" },
        padding: "1rem",
        backgroundColor: "white",
        "&:hover": {
          cursor: "pointer",
          transform: "scale(1.025)",
        },
      }}
    >
      <img
        src={`https://d3473cyq46ezul.cloudfront.net/${brand.brandImage}`}
        style={{
          width: "100%",
          height: isMobileScreen ? "60px" : "70px",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

const BrandsModal = ({ brands, modalOpen, setModalOpen }) => {
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      disableScrollLock={true}
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          outline: "none",
          border: "none",
          position: "relative",
          width: { xs: "95%", tabletMedium: "70%", laptop: "70%" },
          maxWidth: {
            extraLarge: "800px",
            laptop: "80%",
            desktop: "80%",
            xs: "100%",
          },
          maxHeight: {
            xs: "50vh",
            tabletLarge: "50vh",
            desktop: "70vh",
            extraLarge: "50vh",
          },
          background: "linear-gradient(116deg, #F6EDED 23.57%, #DFE8EA 77.15%)",
          padding: { xs: "1rem", laptop: "1.5rem 1.5rem 1.5rem 1.5rem" },
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#aaa",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f4f4f4",
            borderRadius: "4px",
          },
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Close
              onClick={() => handleClose()}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
          <Box>
            <Grid container spacing={{ xs: 2, lpatop: 3 }}>
              {brands?.map((brand, index) => (
                <Grid key={index} item xs={4} laptop={3} desktop={2}>
                  <ModalBrandCard brand={brand} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default BrandsModal;
