import { useQuery } from "@apollo/client";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { QUERY_GET_SERIES_BY_BRAND } from "../../../../../graphql/Query";
import SeriesItem from "./SeriesItem";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { modelSeriesAtom } from "../../../../../store/atoms/selling/modelProducts/modelProductAtom";

const SeriesFilter = () => {
  const location = useLocation();
  const { categoryId, brandId } = useParams();
  const [seriesId, setSeriesId] = useRecoilState(modelSeriesAtom);
  // const [categoryId, setCategoryId] = useState(null);
  // const [brandId, setBrandId] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  useEffect(() => {
    // const urlParams = new URLSearchParams(location.search);
    // const categoryIdFromUrl = urlParams.get("categoryId");
    // const brandIdFromUrl = urlParams.get("brandId");
    // setBrandId(brandIdFromUrl);
    // setCategoryId(categoryIdFromUrl);
  }, [categoryId, brandId]);

  const { data, loading, error } = useQuery(QUERY_GET_SERIES_BY_BRAND, {
    variables: { brandId: brandId, categoryId: categoryId },
  });
  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error....</>;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        padding: "1.5rem",
        gap: "1rem",
        width: "100%",
        borderRadius: "0.5rem",
        position: "sticky",
        height: "calc(100vh - 110px)",
        overflowY: "scroll",
        top: "95px",
        boxShadow:
          "0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11)",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <Box>
        {/* "All" Option */}
        <Box
          sx={{
            padding: "1rem",
            backgroundColor: !seriesId ? "#D0E4F7" : "white", // Active when no series selected
            cursor: "pointer",
            borderRadius: "0.5rem",
            "&:hover": {
              backgroundColor: "#F5F7FA", // Hover effect
            },
          }}
          onClick={() => setSeriesId(null)} // Clear seriesId when "All" is clicked
        >
          <CustomTypography
            variant={"subtitle2"}
            sx={{ fontWeight: !seriesId ? "bold" : "normal" }} // Bold for active
          >
            All
          </CustomTypography>
        </Box>

        {/* Series List */}
        {data?.getSeriesByBrand?.map((series) => (
          <SeriesItem
            key={series.id}
            series={series}
            isActive={seriesId === series.id}
            onClick={() => setSeriesId(series.id)} // Set active series on click
          />
        ))}
      </Box>
    </Box>
  );
};

export default SeriesFilter;
