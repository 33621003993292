import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { FaEye } from "react-icons/fa";
import swal from "sweetalert";
import React, { useState } from "react";
import CustomTypography from "../../../muicomponents/shared/CustomTypography";
import {
  QUERY_GET_ALL_CONDITIONS,
  QUERY_GET_ALL_SELLING_STEPS,
} from "../../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_ADD_SELLING_CONDITION } from "../../../graphql/Mutations";
import EditSellingCondition from "./modals/EditSellingCondition";

const AddCondition = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editCondition, setEditCondition] = useState(null);
  const getImageName = (invoiceLink) => {
    if (!invoiceLink) return "";
    const parts = invoiceLink.split("/");
    return parts[parts.length - 1];
  };
  const {
    data: allSteps,
    loading: allStepsLoading,
    error: allStepsError,
  } = useQuery(QUERY_GET_ALL_SELLING_STEPS);

  const {
    data: allSellingConditions,
    loading: allSellingConditionsLoading,
    error: allSellingConditionsError,
  } = useQuery(QUERY_GET_ALL_CONDITIONS);

  console.log(allSellingConditions);

  const [addSellingCondition, { loading: addSellingConditionLoading }] =
    useMutation(MUTATION_ADD_SELLING_CONDITION, {
      refetchQueries: [QUERY_GET_ALL_CONDITIONS],
    });

  const [condition, setCondition] = useState({
    conditionType: "",
    finalConditionName: "",
    title: "",
    subtitle: "",
    description: "",
    priority: 0,
    step: "",
    image: null,
  });

  const addCondition = async () => {
    try {
      const formData = new FormData();
      if (condition.image) {
        formData.append("file", condition.image);
      }

      const finalCondition = {
        finalConditionName: condition.finalConditionName,
        conditionType: condition.conditionType,
        title: condition.title,
        subtitle: condition.subtitle,
        description: condition.description,
        priority: condition.priority,
        step: condition.step,
        ...(condition.image && { image: formData.get("file") }),
      };

      const { data } = await addSellingCondition({
        variables: {
          sellingCondition: finalCondition,
        },
      });
      swal({
        title: "Success",
        text: data.addSellingCondition.message,
        icon: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCondition({
        finalConditionName: "",
        conditionType: "",
        title: "",
        subtitle: "",
        description: "",
        priority: 0,
        step: "",
        image: null,
      });
    }
  };

  const handleModalOpen = (condition) => {
    setModalOpen(true);
    setEditCondition(condition);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCondition({
      ...condition,
      [name]: name === "priority" ? Number(value) : value,
    });
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCondition({ ...condition, image: file });
    }
  };

  if (allSellingConditionsLoading) {
    return <>Loading...</>;
  }
  if (allSellingConditionsError) {
    return <>Error...</>;
  }
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ margin: "3rem 0", width: { xs: "100%", laptop: "inherit" } }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            width: { laptop: "425px", xs: "100%" },
            gap: "1rem",
          }}
        >
          <Box>
            <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
              Add Condition
            </CustomTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
              minHeight: "200px",
              backgroundColor: "white",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Title"
                variant="outlined"
                name="title"
                value={condition.title}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextareaAutosize
                placeholder="Type Description here...."
                minRows={2}
                style={{ width: "100%", padding: "0.5rem" }}
                name="description"
                value={condition.description}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Subtitle"
                variant="outlined"
                name="subtitle"
                type="text"
                value={condition.subtitle}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Final Condition Name"
                variant="outlined"
                name="finalConditionName"
                type="text"
                value={condition.finalConditionName}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Priority"
                variant="outlined"
                name="priority"
                type="number"
                value={condition.priority === 0 ? "" : condition.priority}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box>
                <Input
                  accept="image/*"
                  id="category-image-upload"
                  type="file"
                  onChange={handleImageChange}
                  sx={{ width: "100%" }}
                />
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Condition Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="conditionType"
                  value={condition.conditionType}
                  label="select"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"mcq"}>multiple choice</MenuItem>
                  <MenuItem value={"yes/no"}>yes/no</MenuItem>
                  <MenuItem value={"select-single-decrement"}>
                    select single decrement
                  </MenuItem>
                  <MenuItem value={"select-single-increment"}>
                    select single increment
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select step{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="step"
                  value={condition.step}
                  label="select"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  {allSteps?.getAllSellingSteps.map((step) => (
                    <MenuItem key={step.id} value={step.id}>
                      {step.stepName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button type="submit" variant="outlined" onClick={addCondition}>
                Add Condition
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            overflowX: "hidden",
            marginTop: "2rem",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            <TableHead className="table-head">
              <TableRow>
                <TableCell>Sl No.</TableCell>
                <TableCell>Condition Title</TableCell>
                <TableCell>Condition Subtitle</TableCell>
                <TableCell>Condition Description</TableCell>
                <TableCell>Condition Image</TableCell>
                <TableCell>Condition Type</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Step Name</TableCell>
                <TableCell>Final Condition Name</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSellingConditions?.getAllSellingConditions.map(
                (condition, index) => (
                  <TableRow key={condition?.id}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="right" sx={{ wordBreak: "break-word" }}>
                      {condition?.title}
                    </TableCell>
                    <TableCell align="right">
                      {condition.subtitle === null || condition.subtitle === ""
                        ? "N/A"
                        : condition.subtitle}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ wordBreak: "break-word", maxWidth: "300px" }}
                    >
                      {condition?.description}
                    </TableCell>
                    <TableCell align="center" sx={{ textAlign: "center" }}>
                      {condition?.image === null ? (
                        <>
                          <CustomTypography variant={"subtitle2"}>
                            N/A
                          </CustomTypography>
                        </>
                      ) : (
                        <>
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="0.5rem"
                          >
                            <Box>{getImageName(condition?.image)}</Box>
                            <Box>
                              <FaEye
                                style={{ cursor: "pointer", fontSize: "1rem" }}
                                onClick={() =>
                                  window.open(
                                    `${`https://d3473cyq46ezul.cloudfront.net/`}${
                                      condition?.image
                                    }`,
                                    "_blank"
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {condition?.conditionType}
                    </TableCell>
                    <TableCell align="right">{condition?.priority}</TableCell>
                    <TableCell align="right">
                      {condition?.step?.stepName}
                    </TableCell>
                    <TableCell align="right">
                      {condition?.finalConditionName === null
                        ? "N/A"
                        : condition.finalConditionName}
                    </TableCell>
                    <TableCell align="'right">
                      <Button
                        variant={"contained"}
                        onClick={() => handleModalOpen(condition)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </TableContainer>
        </Box>
      </Box>
      <EditSellingCondition
        condition={editCondition}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        steps={allSteps?.getAllSellingSteps}
        setEditParentCondition={setEditCondition}
      />
    </>
  );
};

export default AddCondition;
