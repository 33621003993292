import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Dropdown,
  Modal,
} from "react-bootstrap";
import React, { useState } from "react";
import { CREATE_BUY_PRODUCT } from "../../graphql/Mutations";
import { QUERY_All_BUY_ORDERS } from "../../graphql/Query";
import { QUERY_GET_EXPLORE_BRAND } from "../../graphql/Query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import AWS from "aws-sdk";
import uniqid from "uniqid";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";

import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
} from "react-simple-wysiwyg";
import MuiBackdrop from "../../muicomponents/shared/MuiBackdrop";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../store/atoms/addToCart/addToCart";

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});
let imageKey = [];

const s3Client = new S3Client({
  region: "ap-south-1",
  credentials: {
    accessKeyId: "AKIAQ3EGQHQFAW22MOEA",
    secretAccessKey: "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",
  },
});

const sizes = {
  xs: { width: 150, height: 200 },
  sm: { width: 320, height: 400 },
  md: { width: 640, height: 800 },
  lg: { width: 1024, height: 1280 },
  xl: { width: 1280, height: 1600 },
};

const AddBuyOrders = () => {
  const [validated, setValidated] = useState(false);
  const [productNameState, setProductNameState] = useState("");
  const [productSubtitleState, setProductSubtitleState] = useState("");
  const [productPriceState, setProductPriceState] = useState("");
  const [productStorageState, setProductStorageState] = useState("");
  const [category, setCategory] = useState("");
  const [warranty, setWarranty] = useState("");
  const [productCondition, setProCondition] = useState("");
  const [images, setImages] = useState([]);
  const [productBrand, setProductBrand] = useState("");
  const [productRAM, setProductRAM] = useState("");
  const [productMRP, setProductMRP] = useState("");
  const [productLink, setProductLink] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [productType, setProductType] = useState("");
  const [awsImages, setAwsImages] = useState([]);
  const [producytQuantity, setProductQuantity] = useState(0);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  // console.log(awsImages);

  const [imageBox, setImageBox] = useState([]);
  const [createProduct] = useMutation(CREATE_BUY_PRODUCT, {
    refetchQueries: [QUERY_All_BUY_ORDERS],
  });
  const [
    getExploreByBrands,
    { data: getExploreByBrandsdata, loading: ExploreLoading },
  ] = useLazyQuery(QUERY_GET_EXPLORE_BRAND);
  // images selecting
  const handleIamges = (e) => {
    setImages([...e.target.files]);
  };

  // product desc
  const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");

  const [value, setValue] = useState();

  function onChange(e) {
    setValue(e.target.value);
  }

  // Warranty Desc
  const [value2, setValue2] = useState();

  function onChange2(e) {
    setValue2(e.target.value);
  }
  const [value3, setValue3] = useState();

  function onChange3(e) {
    setValue3(e.target.value);
  }
  const [value4, setValue4] = useState();

  function onChange4(e) {
    setValue4(e.target.value);
  }

  const uploadToS3 = async (file, key) => {
    const params = {
      Bucket: "hellofi",
      Key: key,
      Body: file,
      ContentType: "image/webp",
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      // console.log(`Successfully uploaded: ${key}`);
      return key; // Return the uploaded key (URL path)
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const resizeImage = (file, targetWidth, targetHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result; // Load the image into the `img` object
      };

      reader.readAsDataURL(file); // Read the file as a data URL

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions to target width and height
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Calculate the aspect ratio for "cover" effect (stretch to fill)
        const scaleX = targetWidth / img.width;
        const scaleY = targetHeight / img.height;
        const scale = Math.max(scaleX, scaleY); // Ensure the image covers the canvas

        // Calculate the center position for drawing
        const offsetX = (canvas.width - img.width * scale) / 2;
        const offsetY = (canvas.height - img.height * scale) / 2;

        // Draw the image on the canvas, scaled and centered
        ctx.drawImage(
          img,
          offsetX,
          offsetY,
          img.width * scale,
          img.height * scale
        );

        canvas.toBlob(
          (blob) => {
            if (blob) {
              // console.log("Resized image size:", blob.size, "bytes");
              resolve(blob); // Resolve with the resized image Blob
            } else {
              reject(new Error("Failed to resize image"));
            }
          },
          "image/webp", // Output format
          0.3 // Quality (80%)
        );
      };

      img.onerror = (error) => {
        reject(error); // Reject if there's an error loading the image
      };
    });
  };

  const handleImagesUpload = async () => {
    setBackdropOpen(true);

    const uploadPromises = images.map(async (file) => {
      const myUUID = uuidv4();
      const resizedImages = {};

      // Resize and upload for each size
      for (const [size, { width, height }] of Object.entries(sizes)) {
        const resizedImageBlob = await resizeImage(file, width, height);
        const key = `images/product-images/hellofi-${myUUID}-${size}.webp`;
        await uploadToS3(resizedImageBlob, key);

        // Store the key under the size
        resizedImages[size] = key;
      }

      return resizedImages;
    });

    try {
      const awsImagesArray = await Promise.all(uploadPromises);

      // Set the structured images in the state
      setAwsImages(awsImagesArray);

      swal("Success!", "Images uploaded successfully!", "success");
    } catch (error) {
      console.log(error);
      swal("Error!", "Failed to upload images.", "error");
    } finally {
      setBackdropOpen(false);
    }
  };

  // data send
  const handleSave = (event) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    // setValidated(true);
    setBackdropOpen(true);
    createProduct({
      variables: {
        productInput: {
          productName: `${productNameState}`,
          productSubTitle: `${productSubtitleState}`,
          productPrice: `${productPriceState}`,
          productDescription: `${value}`,
          warranty: `${warranty}`,
          warrantyDescription: `${value2}`,
          dimensionsDescrption: `${value3}`,
          AdditionalFeatures: `${value4}`,
          image: imageKey,
          storage: `${productStorageState}`,
          condition: `${productCondition}`,
          productCategory: `${category}`,
          productBrand: `${productBrand}`,
          liveLink: `${productLink}`,
          quantity: `${producytQuantity}`,
          mrp: `${productMRP}`,
          ram: `${productRAM}`,
          networkType: `${networkType}`,
          type: `${productType}`,
          screenSize: `${screenSize}`,
          images: awsImages,
        },
      },
    }).then((data) => {
      setBackdropOpen(false);
      swal({
        title: "Success",
        text: "Product Added Successfully",
        icon: "success",
      });
    });
    setWarranty("");
    setProductNameState("");
    setProductSubtitleState("");
    setProductPriceState("");
    setProductBrand("");
    setCategory("");
    setProCondition("");
    setProductStorageState("");
    setValue("");
    setProductQuantity(0);
    setValue2("");
    setImages([]);
    setProductLink("");
    setProductMRP("");
    setProductRAM("");
    setScreenSize("");
    setProductType("");
    setImages([]);
    setValue3("");
    setValue4("");

    imageKey = [];
  };
  //images Uploading

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    getExploreByBrands({
      variables: {
        category: selectedCategory,
      },
    });
  };
  return (
    <>
      <MuiBackdrop />
      <Container>
        <Card style={{ width: "55rem" }} className="mx-auto d-block">
          <Card.Body>
            <Card.Title style={{ textAlign: "center" }}>
              Add Buy Product Here
            </Card.Title>
            <Form noValidate validated={validated}>
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Dropdown style={{ marginTop: "1rem" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Category
                  </Dropdown.Toggle>
                  <span>{category}</span>
                  <Dropdown.Menu
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false, // Disable flipping to prevent opening in top direction
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10], // Adjust the offset as needed
                          },
                        },
                      ],
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ overflowY: "scroll", height: "200px" }}>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Phones")}
                      >
                        Phones
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Laptops")}
                      >
                        Laptops
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Tablets")}
                      >
                        Tablets
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Smart Watches")}
                      >
                        Smart Watches
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Desktop/Imac")}
                      >
                        Desktop/Imac
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleCategoryChange("Other Accessories")
                        }
                      >
                        Other Accessories
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Dropdown style={{ marginTop: "1rem" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "100%" }}
                  >
                    Warranty
                  </Dropdown.Toggle>
                  <span>{warranty}</span>
                  <Dropdown.Menu
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ overflowY: "scroll", height: "auto" }}>
                      <Dropdown.Item
                        onClick={() => setWarranty("HelloFi Warranty")}
                      >
                        HelloFi Warranty
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setWarranty("Brand Warranty")}
                      >
                        Brand Warranty
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setWarranty("No Warranty")}>
                        No Warranty
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Dropdown style={{ marginTop: "1rem" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Product Condition{" "}
                  </Dropdown.Toggle>
                  <span>{productCondition}</span>
                  <Dropdown.Menu
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false, // Disable flipping to prevent opening in top direction
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10], // Adjust the offset as needed
                          },
                        },
                      ],
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ overflowY: "scroll", height: "120px" }}>
                      <Dropdown.Item
                        onClick={(e) => setProCondition("Unboxed")}
                      >
                        Unboxed
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => setProCondition("Superb")}>
                        Superb
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => setProCondition("Good")}>
                        Good
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => setProCondition("Fair")}>
                        Fair
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => setProCondition("Partially-Fair")}
                      >
                        Partially-Fair
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {category ? (
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Dropdown style={{ marginTop: "1rem" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Choose Product Brand{" "}
                    </Dropdown.Toggle>
                    <span>{productBrand}</span>
                    <Dropdown.Menu
                      popperConfig={{
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false, // Disable flipping to prevent opening in top direction
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10], // Adjust the offset as needed
                            },
                          },
                        ],
                      }}
                      style={{ width: "100%" }}
                    >
                      <div style={{ overflowY: "scroll", height: "120px" }}>
                        {getExploreByBrandsdata &&
                          getExploreByBrandsdata?.getExploreByBrands.map(
                            (data) => {
                              return (
                                <Dropdown.Item
                                  onClick={() =>
                                    setProductBrand(data.brandName)
                                  }
                                >
                                  {data.brandName}
                                </Dropdown.Item>
                              );
                            }
                          )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              ) : (
                ""
              )}

              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Product Name"
                  value={productNameState}
                  onChange={(e) => setProductNameState(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Quantity</Form.Label>
                <Form.Control
                  required
                  type="Number"
                  placeholder="Product quantity"
                  value={producytQuantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    setProductQuantity(value === "" ? "" : parseInt(value));
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Subtitle</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Product Subtitle"
                  value={productSubtitleState}
                  onChange={(e) => setProductSubtitleState(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product MRP</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="MRP"
                  value={productMRP}
                  onChange={(e) => {
                    const value = e.target.value;
                    setProductMRP(value === "" ? "" : parseInt(value));
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Price</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Product Price (Rs)"
                  value={productPriceState}
                  onChange={(e) => {
                    const value = e.target.value;
                    setProductPriceState(value === "" ? "" : parseInt(value));
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Realtime Product Video</Form.Label>
                <Form.Control
                  required
                  type="url"
                  placeholder="Valid youtube link "
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {(category && category == "Phones") ||
              category == "Laptops" ||
              category == "Tablets" ||
              category == "Desktop/Imac" ? (
                <>
                  <Dropdown style={{ marginTop: "1rem" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Product Storage{" "}
                    </Dropdown.Toggle>
                    <span>{productStorageState}</span>
                    <Dropdown.Menu
                      popperConfig={{
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false, // Disable flipping to prevent opening in top direction
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10], // Adjust the offset as needed
                            },
                          },
                        ],
                      }}
                      style={{ width: "100%" }}
                    >
                      <div style={{ overflowY: "scroll", height: "120px" }}>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("16GB")}
                        >
                          16GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("32GB")}
                        >
                          32GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("64GB")}
                        >
                          64GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("128GB")}
                        >
                          128GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("256GB")}
                        >
                          256GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("512GB")}
                        >
                          512GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("1TB")}
                        >
                          1TB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("2TB")}
                        >
                          2TB
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("Above512")}
                        >
                          Above512
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("Below512")}
                        >
                          Below512
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product storage</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Storage Eg 64GB"
                                        value={productStorageState}
                                        onChange={(e) => setProductStorageState(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group> */}
                  <Form.Group controlId="validationCustom01">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Product RAM{" "}
                      </Dropdown.Toggle>
                      <span>{productRAM}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item onClick={(e) => setProductRAM("2GB")}>
                            2GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("3GB")}>
                            3GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("4GB")}>
                            4GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("6GB")}>
                            6GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("8GB")}>
                            8GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("12GB")}>
                            12GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("16GB")}>
                            16GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("32GB")}>
                            32GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("64GB")}>
                            64GB
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  <Form.Group md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Network Type{" "}
                      </Dropdown.Toggle>
                      <span>{networkType}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item onClick={(e) => setNetworkType("4G")}>
                            4G
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setNetworkType("5G")}>
                            5G
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setNetworkType("Only WiFi")}
                          >
                            Only WiFi
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setNetworkType("Cellular + WiFi")}
                          >
                            Cellular + WiFi
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {(category && category == "Laptops") ||
              category == "Desktop/Imac" ? (
                <>
                  <Form.Group md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Screen Size{" "}
                      </Dropdown.Toggle>
                      <span>{screenSize}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Below 13 inches")}
                          >
                            Below 13 inche
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("13-14 inches")}
                          >
                            13-14 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("14-15 inches")}
                          >
                            14-15 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Above 15 inches")}
                          >
                            Above 15 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Below 30 inches")}
                          >
                            Below 30 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Above 30 inches")}
                          >
                            Above 30 inches
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {category && category == "Other Accessories" ? (
                <>
                  <Form.Group md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Product Type{" "}
                      </Dropdown.Toggle>
                      <span>{productType}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Camera")}
                          >
                            Camera
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Airpod")}
                          >
                            Airpod
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Earphone")}
                          >
                            Earphone
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Drone")}
                          >
                            Drone
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Printer")}
                          >
                            Printer
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Description</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value}
                    onChange={onChange}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Warranty Description</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value2}
                    onChange={onChange2}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Return and Replacement Policy</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value3}
                    onChange={onChange3}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Packaging Information</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value4}
                    onChange={onChange4}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Images</Form.Label>
                <Form.Control
                  required
                  type="file"
                  onChange={handleIamges}
                  multiple
                  accept="image/*"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {images
                ? images.map((image) => {
                    return (
                      <span style={{ marginLeft: "1rem" }}> {image.name}</span>
                    );
                  })
                : ""}
              <div>
                <Button
                  style={{ fontSize: "11px" }}
                  onClick={() => handleImagesUpload()}
                  disabled={images ? false : true}
                >
                  {" "}
                  Upload{" "}
                </Button>
              </div>
            </Form>
            <Button
              onClick={() => handleSave()}
              disabled={
                productNameState &&
                producytQuantity &&
                productPriceState &&
                productSubtitleState &&
                value &&
                value2 &&
                value3 &&
                value4 &&
                imageKey &&
                productLink &&
                productMRP
                  ? false
                  : true
              }
              className="mx-auto d-block mt-2"
            >
              {" "}
              Save{" "}
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
export default AddBuyOrders;
