import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ProductVariants = ({ product }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const location = useLocation();

  const productName = new URLSearchParams(location.search).get("productName");
  const [variantId, setVariantId] = useState("");
  const selectedVariant =
    product.variants.find((v) => v.id === variantId) || null;

  const getProductTitle = () => {
    let productName = "";
    if (!selectedVariant) {
      productName = product.productName;
      return productName;
    }
    if (product.isConstantRam) {
      productName = `${product.productName} (${product.ram}/${selectedVariant.storage})`;
      return productName;
    }
    productName = `${product.productName} (${selectedVariant.ram}/${selectedVariant.storage})`;
    return productName;
  };

  useEffect(() => {
    if (selectedVariant === null && productName !== null) {
      navigate(`/sell/product/${product.id}`);
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (variantId) {
      const productTitle = getProductTitle();
      navigate(`/sell/product/${product.id}?productName=${productTitle}`);
    }
  }, [variantId]);

  const handleNavigateToForm = () => {
    const imageUrl = encodeURIComponent(product?.image);

    if (product.hasVariants) {
      if (product.isConstantRam) {
        navigate(
          `/sell/calculator/page?pId=${
            product.id
          }&productName=${getProductTitle()}&productPrice=${
            selectedVariant?.productPrice || ""
          }&imageUrl=${imageUrl}&cId=${product.category}&cn=${
            product.categoryName
          }&stepNo=1`
        );
      } else {
        navigate(
          `/sell/calculator/page?pId=${product.id}&vId=${
            selectedVariant.id
          }&productName=${getProductTitle()}&productPrice=${
            selectedVariant?.productPrice || ""
          }&imageUrl=${imageUrl}&cId=${product.category}&cn=${
            product.categoryName
          }&stepNo=1`
        );
      }
    } else {
      navigate(
        `/sell/calculator/page?pId=${
          product.id
        }&productName=${getProductTitle()}&imageUrl=${imageUrl}&cId=${
          product.category
        }&cn=${product.categoryName}&stepNo=1`
      );
    }
  };

  const handleNavigate = (variant) => {
    setVariantId(variant.id);
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDirection="column"
        gap={"1rem"}
        sx={{
          width: "100%",
          minHeight: "200px",
          background: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "2rem",
          padding: isSmallScreen ? "1rem" : "2rem",
        }}
      >
        {product.hasVariants && productName === null && (
          <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  color: "#3D3C3C",
                  fontSize: { xs: "1rem", laptop: "1.25rem" },
                  fontWeight: "700",
                }}
              >
                Choose Your Variant
              </CustomTypography>
            </Box>
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontSize: { xs: "0.875rem", laptop: "1rem" },
                  fontWeight: "600",
                  color: "#3D3C3C",
                }}
              >
                {product.isConstantRam ? `(Storage)` : "(Storage | RAM)"}
              </CustomTypography>
            </Box>
            <Box>
              <Box display="flex" gap="1rem" sx={{ flexWrap: "wrap" }}>
                {product.variants.map((variant) => (
                  <Box
                    key={variant.id}
                    sx={{
                      border:
                        variant.id === variantId ? "none" : "2px solid #214C7B",
                      borderRadius: "1rem",
                      backgroundColor:
                        variant.id === variantId ? "#214C7B" : "white",
                      padding: "0.5rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minWidth: "80px",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleNavigate(variant)}
                  >
                    <CustomTypography
                      variant="subtitle2"
                      sx={{
                        color: variant.id === variantId ? "white" : "#214C7B",
                        fontSize: { xs: "0.875rem", laptop: "0.9rem" },
                        fontWeight: variant.id === variantId ? "600" : "500",
                      }}
                    >
                      {product.isConstantRam
                        ? variant.storage
                        : `${variant.ram} | ${variant.storage}`}
                    </CustomTypography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  color: "#FF7070",
                  fontSize: "0.85rem",
                  fontWeight: "600",
                  marginTop: "1rem",
                }}
              >
                {`Please select the Appropriate Storage ${
                  product.isConstantRam ? "" : "and RAM "
                }as mentioned in the Title`}
              </CustomTypography>
            </Box>
          </Box>
        )}

        {((product.hasVariants &&
          selectedVariant !== null &&
          productName !== null) ||
          !product.hasVariants) && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={{ xs: "0.5rem", laptop: "1rem" }}
          >
            <Box>
              <CustomTypography
                variant={"h6"}
                sx={{
                  fontSize: { xs: "1rem", laptop: "1.25rem" },
                  color: "#214C7B",
                  fontWeight: 700,
                }}
              >
                {selectedVariant === null ? product.productName : productName}
              </CustomTypography>
            </Box>
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontWeight: "400",
                  color: "#868484",
                  fontSize: { xs: "0.875rem", laptop: "1rem" },
                  fontWeight: "600",
                }}
              >
                Get Upto
              </CustomTypography>
              <CustomTypography
                variant="subtitle1"
                sx={{
                  color: "#B71515",
                  fontSize: { xs: "1.2rem", laptop: "1.6rem" },
                  fontWeight: "700",
                }}
              >
                Rs.{" "}
                {product.hasVariants
                  ? `${selectedVariant.productPrice}`
                  : `${product.productPrice}`}
              </CustomTypography>
            </Box>

            <Box>
              <Button
                variant={"contained"}
                sx={{
                  backgroundColor: "#214C7B",
                  borderRadius: "0.6rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#214C7B",
                  },
                }}
                onClick={handleNavigateToForm}
              >
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{ fontWeight: "600" }}
                >
                  Get Exact Value
                </CustomTypography>
              </Button>
            </Box>
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  color: "#FF7070",
                  fontSize: { xs: "0.75rem", laptop: "0.75rem" },
                  fontWeight: "600",
                  marginTop: { xs: "0rem", laptop: "1rem" },
                }}
              >
                The above price is purely based on your inputs. Please provide
                the correct information and continue until the final page for
                more accurate pricing
              </CustomTypography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProductVariants;
