import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { RiBillLine } from "react-icons/ri";
import { MdCable } from "react-icons/md";
import { LuHeadphones } from "react-icons/lu";
import { FaIdCard } from "react-icons/fa6";
import { LuShoppingCart } from "react-icons/lu";
import { RiSimCardLine } from "react-icons/ri";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { ArrowDownward, KeyboardArrowRight } from "@mui/icons-material";
import { RiArrowDownSFill } from "react-icons/ri";
import { selectedAssessmentState } from "../../../../../store/atoms/selling/sellingForms/sellingForms";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import transformStepToRecoilState from "../../../../../utils/transformStepToRecoilState";
import useSnackbarNotifier from "../../../../shared/hooks/useSnackbarNotifier";
import { useMutation } from "@apollo/client";
import { MUTATION_ADD_SELLING_ORDER } from "../../../../../graphql/Mutations";

const Step4All = ({ step }) => {
  const [selectedCondition, setSelectedCondition] = useRecoilState(
    selectedAssessmentState
  );

  const [addSellingOrder, { loading: sellingOrderLoading }] = useMutation(
    MUTATION_ADD_SELLING_ORDER
  );

  const showSnackbar = useSnackbarNotifier();
  const navigate = useNavigate();
  const location = useLocation();
  const productName = new URLSearchParams(location.search).get("productName");
  const productId = new URLSearchParams(location.search).get("pId");
  const productPrice = new URLSearchParams(location.search).get("productPrice");

  const imageURL = new URLSearchParams(location.search).get("imageUrl");
  const cId = new URLSearchParams(location.search).get("cId");
  const cn = new URLSearchParams(location.search).get("cn");
  let stepNo = new URLSearchParams(location.search).get("stepNo");

  const [selections, setSelections] = useState({});

  useEffect(() => {
    if (step && selectedCondition.length > 0) {
      const transformedStep = transformStepToRecoilState(step);
      setSelectedCondition((prevState) => {
        const stepExists = prevState.some(
          (existingStep) => existingStep?.id === transformedStep?.id
        );

        if (stepExists) {
          return prevState;
        } else {
          return [...prevState, transformedStep];
        }
      });
    }
  }, [step]);

  useEffect(() => {
    if (
      selectedCondition.length === 0 &&
      selectedCondition?.length < step?.stepNumber
    ) {
      navigate(
        `/sell/calculator/page?pId=${productId}&productName=${productName}&productPrice=${productPrice}&imageUrl=${imageURL}&cId=${cId}&cn=${cn}&stepNo=${1}`
      );
    }
  }, [selectedCondition, step?.stepNumber, navigate, cId]);

  const handleNext = async () => {
    const currentStepData = selectedCondition.find((s) => s.id === step.id);
    const requiredConditions = step.conditions.filter(
      (condition) => condition?.conditionType !== "select-single-increment"
    );

    const allRequiredSelected = requiredConditions.every((condition) => {
      const conditionData = currentStepData?.conditions.find(
        (c) => c.id === condition.id
      );
      return conditionData?.options.some((option) => option.isSelected);
    });
    if (!allRequiredSelected) {
      showSnackbar(
        "Please complete all required selections before proceeding",
        "warning"
      );
      return;
    }

    try {
      const sellingProduct = {
        pid: productId,
        productName: productName,
        productPrice: parseInt(productPrice),
        productImage: imageURL,
        cId: cId,
        cn: cn,
      };

      const res = await addSellingOrder({
        variables: {
          selectedSellingData: selectedCondition,
          productData: sellingProduct,
          action: "quote-final-price",
        },
      });

      navigate(
        `/sell/quote/details?orderId=${res.data.addSellingOrder.sellingOrderId}`
      );
    } catch (error) {
      showSnackbar("Failed to process order. Please try again.", "error");
      console.error("Error processing order:", error);
    }
  };

  const handleSingleSelectChange = (conditionId, isSelected) => {
    setSelectedCondition((prevState) =>
      prevState.map((stepState) => {
        if (stepState.id === step.id) {
          return {
            ...stepState,
            conditions: stepState.conditions.map((condition) => {
              if (condition.id === conditionId) {
                return {
                  ...condition,
                  options: condition.options.map((option) => ({
                    ...option,
                    isSelected: isSelected,
                  })),
                };
              }
              return condition;
            }),
          };
        }
        return stepState;
      })
    );
  };

  const handleDropdownSelect = (conditionId, selectedOption) => {
    setSelectedCondition((prevState) =>
      prevState.map((stepState) => {
        if (stepState.id === step.id) {
          return {
            ...stepState,
            conditions: stepState.conditions.map((condition) => {
              if (condition.id === conditionId) {
                return {
                  ...condition,
                  options: condition.options.map((option) => ({
                    ...option,
                    isSelected: option.id === selectedOption.id,
                  })),
                };
              }
              return condition;
            }),
          };
        }
        return stepState;
      })
    );
  };

  const getSelectedOption = (conditionId) => {
    const currentStepData = selectedCondition.find((s) => s.id === step.id);
    const condition = currentStepData?.conditions.find(
      (c) => c.id === conditionId
    );
    const selectedOption = condition?.options.find(
      (option) => option.isSelected
    );

    if (selectedOption) {
      return {
        id: selectedOption.id,
        label: selectedOption.selectedOptionName || selectedOption.label,
      };
    }
    return null;
  };

  const isOptionSelected = (conditionId) => {
    const currentStepData = selectedCondition.find((s) => s.id === step.id);
    const condition = currentStepData?.conditions.find(
      (c) => c.id === conditionId
    );
    return condition?.options.some((option) => option.isSelected);
  };

  const lastCondition = step?.conditions[step?.conditions?.length - 1];

  const getIcon = (title, isSelected) => {
    const iconStyle = { color: isSelected ? "#FFF" : "#214C7B" };
    switch (title) {
      case "Box":
        return <BsBoxSeam size={35} style={iconStyle} />;
      case "Bill":
        return <RiBillLine size={35} style={iconStyle} />;
      case "Adapter + Data Cable":
        return <MdCable size={35} style={iconStyle} />;
      case "Purchased Place":
        return <LuShoppingCart size={35} style={iconStyle} />;
      case "Additional Insurance":
        return <FaIdCard size={35} style={iconStyle} />;
      case "Sim Type":
        return <RiSimCardLine size={35} style={iconStyle} />;
      case "Is this device under Finance":
        return <HiOutlineClipboardDocumentList size={35} style={iconStyle} />;
      default:
        return null;
    }
  };
  if (
    selectedCondition &&
    selectedCondition.length < step.stepNumber &&
    sellingOrderLoading
  ) {
    return <>Loading....</>;
  }

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box>
        <Grid container spacing={{ xs: 1, laptop: 2 }}>
          {step.conditions
            .slice(0, step.conditions.length - 1)
            .map((condition, index) => (
              <Grid
                key={index}
                tabletMedium={6}
                item
                xs={12}
                laptop={4}
                desktop={3}
              >
                <Box sx={{ width: "100%", height: "100%" }}>
                  {condition.conditionType === "select-single-decrement" ||
                  condition.conditionType === "select-single-increment" ? (
                    <SingleSelect
                      condition={condition}
                      getIcon={getIcon}
                      index={index}
                      onSelect={handleSingleSelectChange}
                      isSelected={isOptionSelected(condition.id)}
                    />
                  ) : (
                    <MultipleSelect
                      condition={condition}
                      getIcon={getIcon}
                      onSelect={(option) =>
                        handleDropdownSelect(condition.id, option)
                      }
                      selectedOption={getSelectedOption(condition.id)}
                    />
                  )}
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
        }}
      >
        <Box>
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              color: "#214C7B",
              fontWeight: "600",
              fontSize: "1.25rem",
            }}
          >
            {lastCondition.title}
          </CustomTypography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {step.conditions[step.conditions.length - 1].options.map(
              (option, index) => (
                <Grid
                  item
                  xs={12}
                  tabletMedium={6}
                  laptop={4}
                  desktop={3}
                  key={index}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "50px",
                      border: "1px solid #214C7B",
                      borderRadius: "10px",
                      cursor: "pointer",
                      backgroundColor:
                        getSelectedOption(lastCondition.id)?.id === option.id
                          ? "#214C7B"
                          : "#FFF",
                      color:
                        getSelectedOption(lastCondition.id)?.id === option.id
                          ? "#FFF"
                          : "#214C7B",
                    }}
                    onClick={() =>
                      handleDropdownSelect(
                        step.conditions[step.conditions.length - 1].id,
                        option
                      )
                    }
                  >
                    <CustomTypography
                      variant={"subtitle2"}
                      sx={{
                        fontWeight:
                          getSelectedOption(lastCondition.id)?.id === option.id
                            ? "700"
                            : "500",
                      }}
                    >
                      {option.label}
                    </CustomTypography>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="outlined"
          onClick={handleNext}
          sx={{
            backgroundColor: "#214C7B",
            borderRadius: "10px",
            color: "white",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#214C7B",
            },
          }}
        >
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Next <KeyboardArrowRight />
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

const SingleSelect = ({ condition, getIcon, index, onSelect, isSelected }) => {
  const handleClick = () => {
    onSelect(condition.id, !isSelected);
  };

  return (
    <Box
      display={"flex"}
      onClick={handleClick}
      sx={{
        width: "100%",
        height: { xs: "200px", laptop: "250px" },
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: { xs: "0.75rem", laptop: "1.5rem" },
        backgroundColor: isSelected ? "#214C7B" : "#FFF",
        color: isSelected ? "#FFF" : "#214C7B",
        cursor: "pointer",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        sx={{ flexGrow: 1, gap: "1rem" }}
      >
        <Box display={"flex"} justifyContent={"center"} sx={{ width: "100%" }}>
          {getIcon(condition.title, isSelected)}
        </Box>
        <Box>
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              textAlign: "center",
              color: isSelected ? "#FFF" : "#214C7B",
              fontWeight: "600",
              fontSize: { xs: "0.875rem", laptop: "1.1rem" },
            }}
          >
            {condition.title}
          </CustomTypography>
          <CustomTypography
            variant="subtitle2"
            sx={{
              textAlign: "center",
              color: isSelected ? "#FFF" : "#214C7B",
              fontSize: { xs: "0.75rem", laptop: "0.875rem" },
            }}
          >
            {condition.subtitle}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

const MultipleSelect = ({ condition, getIcon, onSelect, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        display="flex"
        sx={{
          width: "100%",
          height: { xs: "200px", laptop: "250px" },
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          padding: { xs: "0.75rem", laptop: "1.5rem" },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          sx={{ flexGrow: 1 }}
        >
          <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
            {getIcon(condition.title)}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            onClick={() => setIsOpen(!isOpen)}
            sx={{ cursor: "pointer" }}
          >
            <CustomTypography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                color: "#214C7B",
                fontWeight: "600",
                fontSize: { xs: "0.875rem", laptop: "1.1rem" },
              }}
            >
              {condition.title}
            </CustomTypography>

            <Box display="flex" justifyContent="center" mt={1}>
              <RiArrowDownSFill
                size={32}
                style={{
                  color: "#214C7B",
                  transform: isOpen ? "rotate(180deg)" : "none",
                  transition: "transform 0.3s",
                }}
              />
            </Box>

            {selectedOption && !isOpen && (
              <CustomTypography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "#214C7B",
                  mt: 1,
                  backgroundColor: "#f0f7ff",
                  padding: "0.5rem",
                  borderRadius: "4px",
                  fontSize: "0.875rem",
                }}
              >
                {selectedOption.label}
              </CustomTypography>
            )}

            {isOpen && (
              <CustomDropdown
                options={condition.options}
                selectedOption={selectedOption}
                onOptionClick={handleOptionClick}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CustomDropdown = ({ options, selectedOption, onOptionClick }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        minWidth: { xs: "100px", laptop: "200px" },
        p: 2,
        mt: 2,
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        border: "1px solid #e0e0e0",
        zIndex: 10,
        "&::before": {
          content: '""',
          position: "absolute",
          top: -20,
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "20px solid transparent",
          borderRight: "20px solid transparent",
          borderBottom: "20px solid #fff",
          filter: "drop-shadow(0 -2px 2px rgba(0,0,0,0.1))",
          zIndex: 1,
        },
      }}
    >
      {options.map((option, index) => (
        <Box
          key={index}
          onClick={() => onOptionClick(option)}
          sx={{
            padding: "0.75rem",
            cursor: "pointer",
            borderRadius: "4px",
            backgroundColor:
              selectedOption?.id === option.id ? "#f0f7ff" : "transparent",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <CustomTypography
            variant="subtitle2"
            sx={{
              color: selectedOption?.id === option.id ? "#214C7B" : "#333",
              fontWeight: selectedOption?.id === option.id ? "600" : "400",
            }}
          >
            {option.label}
          </CustomTypography>
        </Box>
      ))}
    </Box>
  );
};
export default Step4All;
