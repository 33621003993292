import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_CREATE_SERIES } from "../../graphql/Mutations";
import { MUTATION_DELETE_SERIES } from "../../graphql/Mutations";
import { MUTATION_UPDATE_SERIES } from "../../graphql/Mutations";
import { QUERY_GET_SERIES } from "../../graphql/Query";
import { QUERY_GET_ALL_BRANDS } from "../../graphql/Query";

import swal from "sweetalert";

const AddSellSeries = () => {
  const [brandId, setBrandId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [seriesName, setSeriesName] = useState("");
  const [seriesNameEdit, setSeriesNameEdit] = useState("");
  const [brandNameEdit, setbrandNameEdit] = useState("");
  const [selectedSeries, setSelectedSeries] = useState("");
  const { data: getAllBrands, loading: allBrandLoading } =
    useQuery(QUERY_GET_ALL_BRANDS);

  const [validated, setValidated] = useState(false);
  const { data: getSeries, loading: getSeriesLoading } =
    useQuery(QUERY_GET_SERIES);

  // console.log(getSeries);
  const [createSeries, { loading: seriesLoading }] = useMutation(
    MUTATION_CREATE_SERIES,
    {
      refetchQueries: [QUERY_GET_SERIES],
    }
  );
  const [deleteSeries, { loading: delLoading }] = useMutation(
    MUTATION_DELETE_SERIES,
    {
      refetchQueries: [QUERY_GET_SERIES],
    }
  );
  const [updateSeries, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_SERIES,
    {
      refetchQueries: [QUERY_GET_SERIES],
    }
  );
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      createSeries({
        variables: {
          seriesInput: {
            id: `${brandId}`,
            brandName: `${brandName}`,
            seriesName: `${seriesName}`,
          },
        },
      })
        .then(() => {
          swal({
            title: "Success",
            text: "Series Added successfully",
            icon: "success",
          });
          setBrandName("");
          setSeriesName("");
        })
        .catch((e) => console.log(e));
    }
  };
  const showDelModal = (id) => {
    deleteSeries({
      variables: {
        seriesId: `${id}`,
      },
    });
  };
  const showEditModal = (series) => {
    handleShow();
    setSelectedSeries(series);
    setSeriesNameEdit(series.seriesName);
    setbrandNameEdit(series.brandName);
  };
  let count = 1;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEdit = () => {
    updateSeries({
      variables: {
        seriesInput: {
          id: `${selectedSeries.id}`,
          brandName: `${brandNameEdit}`,
          seriesName: `${seriesNameEdit}`,
        },
      },
    })
      .then(() => {
        swal({
          title: "Success",
          text: "Series updated successfully",
          icon: "success",
        });
      })
      .catch((e) => console.log(e));
    handleClose();
  };

  return (
    <>
      <Container style={{ padding: 20 }} fluid>
        <Row>
          <Col md={4}>
            <div style={{ padding: "1rem" }} className="box-shadow">
              <h3 style={{ textAlign: "center" }}>Add Series Here</h3>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="Enter Brand Name "
                                            onChange={(e) => setBrandName(e.target.value)}
                                            value={brandName}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                  <Dropdown drop="down">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ width: "100%" }}
                    >
                      Select Brand
                    </Dropdown.Toggle>
                    <span>{brandName}</span>
                    <Dropdown.Menu
                      popperConfig={{
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false, // Disable flipping to prevent opening in top direction
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10], // Adjust the offset as needed
                            },
                          },
                        ],
                      }}
                      style={{ width: "100%" }}
                    >
                      <div style={{ overflowY: "scroll", height: "300px" }}>
                        {getAllBrands &&
                          getAllBrands?.getAllBrands.map((brand) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  setBrandName(brand.brandName);
                                  setBrandId(brand.id);
                                }}
                              >
                                {brand.brandName} - {brand.category}
                              </Dropdown.Item>
                            );
                          })}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="text"
                      className="txtinpt"
                      placeholder="Enter Series Name "
                      onChange={(e) => setSeriesName(e.target.value)}
                      value={seriesName}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button type="submit" className="mx-auto d-block">
                  Add Brand
                </Button>
              </Form>
            </div>
          </Col>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr>
                  <th>s.no.</th>
                  <th>Brand Name</th>
                  <th>Series Name</th>
                  <th>Category</th>
                  <th>Remove Brand</th>
                  <th>Edit Brand</th>
                </tr>
              </thead>
              <tbody>
                {getSeriesLoading ? (
                  <Spinner
                    animation="border"
                    variant="success"
                    className="mx-auto d-block"
                  />
                ) : (
                  getSeries &&
                  getSeries?.getSeries.map((series) => {
                    return (
                      <tr className="table-data">
                        <td> {count++}</td>
                        <td> {series?.brand?.brandName} </td>
                        <td> {series.seriesName} </td>
                        <td>{series?.brand?.category}</td>
                        <td>
                          <Button
                            variant="danger"
                            className="mx-auto d-block"
                            onClick={() => showDelModal(series.id)}
                          >
                            -
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="success"
                            className="mx-auto d-block"
                            onClick={() => showEditModal(series)}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Dropdown drop="down">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{ width: "100%" }}
              >
                {brandNameEdit}
              </Dropdown.Toggle>
              <span></span>
              <Dropdown.Menu
                popperConfig={{
                  modifiers: [
                    {
                      name: "flip",
                      enabled: false, // Disable flipping to prevent opening in top direction
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10], // Adjust the offset as needed
                      },
                    },
                  ],
                }}
                style={{ width: "100%" }}
              >
                <div style={{ overflowY: "scroll", height: "300px" }}>
                  {getAllBrands &&
                    getAllBrands?.getAllBrands.map((brand) => {
                      return (
                        <Dropdown.Item
                          onClick={() => setbrandNameEdit(brand.brandName)}
                        >
                          {brand.brandName}
                        </Dropdown.Item>
                      );
                    })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Control
              required
              type="text"
              value={seriesNameEdit}
              className="txtinpt"
              onChange={(e) => setSeriesNameEdit(e.target.value)}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSellSeries;
