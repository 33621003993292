import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { modelSeriesAtom } from "../../../../../store/atoms/selling/modelProducts/modelProductAtom";
import { QUERY_GET_SERIES_BY_BRAND } from "../../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SeriesFilterMobile = () => {
  const theme = useTheme();
  const { categoryId, brandId } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const location = useLocation();
  const [seriesId, setSeriesId] = useRecoilState(modelSeriesAtom);
  // const [categoryId, setCategoryId] = useState(null);
  // const [brandId, setBrandId] = useState(null);

  useEffect(() => {
    // const urlParams = new URLSearchParams(location.search);
    // const categoryIdFromUrl = urlParams.get("categoryId");
    // const brandIdFromUrl = urlParams.get("brandId");
    // setBrandId(brandIdFromUrl);
    // setCategoryId(categoryIdFromUrl);
  }, [categoryId, brandId]);

  const { data, loading, error } = useQuery(QUERY_GET_SERIES_BY_BRAND, {
    variables: { brandId: brandId, categoryId: categoryId },
  });

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>Error....</>;
  }
  return (
    <Box
      sx={{
        position: "sticky",
        overflowX: "scroll",
        top: isMobile ? "65px" : "80px",
        left: 0,
        width: "100%",
        zIndex: "50",
        backgroundColor: "white",
        padding: "0.75rem",
        minWidth: "300px",
        minHeight: "40px",
        boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box display={"flex"} gap={"0.5rem"} sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            border: seriesId === null ? "none" : "1px solid #214C7B;",
            backgroundColor: seriesId === null ? "#214C7B" : "white",
            padding: "0.5rem",
            borderRadius: "2rem",
            minWidth: "60px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => setSeriesId(null)}
        >
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              fontWeight: seriesId === null ? "700" : "500",
              color: seriesId === null ? "white" : "#214C7B",
              whiteSpace: "nowrap",
              overflow: "visible",
              textOverflow: "clip",
            }}
          >
            All
          </CustomTypography>
        </Box>
        {data.getSeriesByBrand.map((series) => (
          <Box
            key={series.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              backgroundColor: seriesId === series.id ? "#214C7B" : "white",
              border: seriesId === series.id ? "none" : "1px solid #214C7B;",
              padding: "0.5rem",
              borderRadius: "2rem",
            }}
            onClick={() => setSeriesId(series.id)}
          >
            <CustomTypography
              variant={"subtitle2"}
              sx={{
                fontWeight: seriesId === series.id ? "700" : "500",
                color: seriesId === series.id ? "white" : "#214C7B",
                whiteSpace: "nowrap",
                overflow: "visible",
                textOverflow: "clip",
              }}
            >
              {series.seriesName}
            </CustomTypography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SeriesFilterMobile;
