import React, { useState } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  ShoppingBagOutlined,
  SwapHorizOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import BottomNavbar from "./BottomNavbar";
import SellingModal from "../modals/SellingModal";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [sellModalOpen, setSellModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("extraLarge"));

  const [modals, setModals] = useState({
    sell: false,
    buy: false,
    exchange: false,
  });

  const handleModalOpen = (modalType) => {
    setModals({ ...modals, [modalType]: true });
  };

  const navItems = [
    {
      icon: <HomeOutlined />,
      label: "Home",
      path: "/",
      type: "link",
      action: () => navigate("/"),
    },
    {
      icon: <ShoppingCartOutlined />,
      label: "Buy",
      type: "modal",
      action: () => handleModalOpen("buy"),
    },
    {
      icon: <ShoppingBagOutlined />,
      label: "Sell",
      type: "modal",
      action: () => handleModalOpen("sell"),
    },
    {
      icon: <SwapHorizOutlined />,
      label: "Exchange",
      type: "modal",
      action: () => handleModalOpen("exchange"),
    },
    {
      icon: <PersonOutlineOutlined />,
      label: "Profile",
      path: "/profile",
      type: "link",
      action: () => navigate("/profile"),
    },
  ];

  const desktopItems = [
    ...navItems,
    {
      icon: null,
      label: "Need Help",
      path: "/help",
      type: "link",
      action: () => navigate("/help"),
    },
  ];

  const handleItemClick = (item) => {
    item.action();
  };

  return (
    <>
      {!isSmallScreen ? (
        <Box
          sx={{
            position: "relative",
            padding: "1rem 0",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            backgroundColor: "white",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              maxWidth: "1280px",
              margin: !isNotDesktop ? "0 auto" : "0 2rem",
            }}
          >
            <Grid container spacing={2}>
              {desktopItems.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  laptop={2}
                  key={index}
                  onClick={() => handleItemClick(item)}
                >
                  <Box
                    display="flex"
                    gap="0.5rem"
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item.icon && <Box>{item.icon}</Box>}
                    <CustomTypography
                      variant="subtitle1"
                      sx={{ fontWeight: "600", fontSize: "0.875rem" }}
                    >
                      {item.label}
                    </CustomTypography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      ) : (
        <BottomNavbar navItems={navItems} onItemClick={handleItemClick} />
      )}
      <SellingModal modalOpen={modals.sell} setModalOpen={setModals} />
    </>
  );
};

export default Navbar;
