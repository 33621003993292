import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const ResponsiveContainer = ({ children, sx }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:899px)");
  const isSmall = useMediaQuery("(min-width:900px) and (max-width:1280px)");

  const maxWidth = isMobile || isTablet ? "100%" : "1280px";
  const margin = isSmall
    ? "0 1rem"
    : isTablet
    ? "0 0.5rem"
    : isMobile
    ? "0"
    : "0 auto";

  const padding = isSmall
    ? "0 1rem"
    : isTablet
    ? "0.25rem"
    : isMobile
    ? "0"
    : "0 1rem";

  return (
    <Box
      sx={{
        maxWidth,
        padding,
        margin,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ResponsiveContainer;
