import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import DiscountFunction from "../../../../utils/DiscountFunction";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const OtherItems = ({ products, orderType, orderNo, payment }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const navigate = useNavigate();
  const handleNavigate = (itemId) => {
    navigate(`/order_details?order_id=${orderNo}&item_id=${itemId}`);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          sx={{ borderBottom: "1px solid #f0f0f0", paddingBottom: "0.5rem" }}
        >
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              fontWeight: "600",
              margin: isSmallScreen ? "0 0.75rem 0 0.75rem" : "1rem 0 0 0",
            }}
          >
            Other Items in this order
          </CustomTypography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
          {products.length > 0 &&
            products.map((item) => {
              const status = item?.unit?.status?.at(-1);

              return (
                <Box
                  key={item.id}
                  sx={{
                    marginBottom: "1rem",
                    background:
                      "linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    minHeight: "60px",
                    width: "100%",
                    borderRadius: isSmallScreen ? "0" : "0.5rem",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleNavigate(item.itemId)}
                >
                  <Box
                    sx={{
                      padding: isSmallScreen ? "0.75rem" : "1rem",
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                      gap: isSmallScreen ? "0.5rem" : "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box display={"flex"} flexBasis={"55%"} gap={"0.5rem"}>
                      {" "}
                      <Box flexBasis={isSmallScreen ? "" : "10%"}>
                        <img
                          src={`${`https://d3473cyq46ezul.cloudfront.net/`}${
                            item.product.images[0].sm
                          }`}
                          style={{
                            width: isSmallScreen ? "100px" : "125px",
                            height: isSmallScreen ? "100px" : "125px",
                            borderRadius: "0.5rem",
                          }}
                        />
                      </Box>
                      <Box flexBasis={isSmallScreen ? "" : "90%"} flexGrow={1}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          flexGrow={"1"}
                        >
                          <CustomTypography
                            variant={"subtitle1"}
                            sx={{
                              fontWeight: "600",
                              display: "-webkit-box",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              lineHeight: "1.5em",
                              maxHeight: "3em",
                              fontSize: isSmallScreen ? "0.875rem" : "1rem",
                            }}
                          >
                            {item.product.productName}
                          </CustomTypography>

                          <CustomTypography
                            variant={"subtitle2"}
                            sx={{
                              fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
                            }}
                          >
                            Condition:{" "}
                            <span
                              style={{
                                fontWeight: 700,
                                color: "rgb(6, 155, 170)",
                              }}
                            >
                              {item.product.condition}
                            </span>
                          </CustomTypography>
                          <CustomTypography
                            variant={"subtitle2"}
                            sx={{
                              fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
                            }}
                          >
                            <span
                              style={{
                                borderRadius: "1rem",
                                backgroundColor: "#D8CACA",
                                padding: "2px 8px",
                                fontWeight: "700",
                                fontSize: "0.875rem",
                              }}
                            >
                              Qty: {item.quantity}
                            </span>
                          </CustomTypography>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={"0.5rem"}
                          >
                            <CustomTypography
                              variant={"subtitle1"}
                              sx={{
                                fontWeight: "700",

                                fontSize: isSmallScreen ? "0.875rem" : "1rem",
                              }}
                            >
                              ₹{item.product.productPrice}{" "}
                            </CustomTypography>
                            <CustomTypography
                              variant={"subtitle1"}
                              style={{
                                color: "#94969f",
                                fontSize: isSmallScreen ? "0.875rem" : "1rem",
                                fontWeight: 0,
                                textDecoration: "line-through",
                              }}
                            >
                              {item.product.mrp}
                            </CustomTypography>
                            <CustomTypography
                              variant={"subtitle1"}
                              sx={{
                                fontWeight: "500",

                                color: "#f16565",
                                fontSize: isSmallScreen ? "0.875rem" : "1rem",
                              }}
                            >
                              {DiscountFunction(
                                item.product.productPrice,
                                item.product.mrp
                              )}
                              % OFF
                            </CustomTypography>
                          </Box>
                          <CustomTypography
                            variant={"subtitle2"}
                            sx={{
                              fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
                            }}
                          >
                            Warranty:{" "}
                            <span style={{ fontWeight: 700 }}>
                              {item.product.warranty}
                            </span>
                          </CustomTypography>
                        </Box>
                      </Box>
                    </Box>

                    {status?.status === "failed" ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: isSmallScreen ? "flex-start" : "flex-end",
                          justifyContent: "center",
                          flexBasis: isSmallScreen ? "" : "55%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              backgroundColor: "#f16565",
                              transition: "background-color 0.3s ease",
                            }}
                          ></Box>
                          <CustomTypography
                            variant={"subtitle1"}
                            sx={{
                              paddingRight: "1rem",
                              fontWeight: "600",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            {status.message}
                          </CustomTypography>
                        </Box>
                        <CustomTypography
                          variant={"body2"}
                          sx={{ paddingRight: "1rem" }}
                        >
                          {status.description}
                        </CustomTypography>
                      </Box>
                    ) : status === undefined ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: isSmallScreen
                            ? "flex-start"
                            : "center",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                            border: "2px solid #ff9f00",
                            transition: "background-color 0.3s ease",
                          }}
                        ></Box>{" "}
                        <CustomTypography
                          variant={"subtitle2"}
                          sx={{
                            fontWeight: "600",
                            fontSize: isSmallScreen ? "0.875rem" : "1rem",
                          }}
                        >
                          {payment.pendingReason}
                        </CustomTypography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: isSmallScreen ? "flex-start" : "flex-end",
                          justifyContent: "center",
                        }}
                        flexBasis={isSmallScreen ? "" : "55%"}
                        flexGrow={1}
                      >
                        <CustomTypography
                          variant={"subtitle1"}
                          sx={{
                            paddingRight: "1rem",
                            fontWeight: "600",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.5rem",
                            fontSize: isSmallScreen ? "0.875rem" : "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              backgroundColor:
                                status.status !== "failed" &&
                                status.status !== "cancelled"
                                  ? "rgb(6, 155, 170)"
                                  : "#f16565",
                              transition: "background-color 0.3s ease",
                            }}
                          ></Box>{" "}
                          {/* {item} */}
                          Order {status.status} on{" "}
                          {format(
                            new Date(Number(status.createdAt)),
                            "dd MMM, yyyy"
                          )}
                        </CustomTypography>
                        <CustomTypography
                          variant={"body2"}
                          sx={{
                            paddingRight: "1rem",
                            fontSize: isSmallScreen ? "0.80rem" : "0.85rem",
                          }}
                        >
                          {status.message}
                        </CustomTypography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    </>
  );
};

export default OtherItems;
