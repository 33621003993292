import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import ConditionOptionTitle from "./ConditionOptionTitle";

const QuoteSelectedDetails = ({ sellingOrderData }) => {
  return (
    <Box
      sx={{
        height: { xs: "100%", laptop: "60vh", desktop: "calc(100vh - 137px)" },
        background: "#FFFDFD",
        borderRadius: "1rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
        padding: "1.5rem",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "grey",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#b0b0b0",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#e0e0e0",
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          height: "100%",
          width: "100%",
          gap: "1rem",
        }}
      >
        <Box>
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              color: "#214C7B",
              fontSize: {
                xs: "1.125rem",
                desktop: "1.25rem",
              },
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Device Report
          </CustomTypography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
          {sellingOrderData.sellingSteps.map((step) => (
            <Box
              key={step.id}
              display={"flex"}
              flexDirection={"column"}
              gap={"0.5rem"}
            >
              <Box>
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{
                    fontSize: {
                      xs: "0.95rem",
                      tabletMedium: "1rem",
                      laptop: "1.125rem",
                    },
                    fontWeight: "600",
                  }}
                >
                  {step.finalStepTitle}
                </CustomTypography>
              </Box>
              <Box>
                <ConditionOptionTitle conditions={step.conditions} />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteSelectedDetails;
