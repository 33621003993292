// const transformStepToRecoilState = (step) => {
//   return {
//     id: step.id,
//     stepNumber: step.stepNumber,
//     totalSteps: step.totalSteps,
//     finalStepTitle: step.finalStepTitle,
//     conditions: step.conditions.map((condition) => ({
//       conditionType: condition.conditionType,
//       id: condition.id,
//       finalConditionName: condition.finalConditionName,
//       options: condition.options.map((option) => ({
//         id: option.id,
//         selectedOptionName: option.selectedOptionName,
//         notSelectedOptionName: option.notSelectedOptionName,
//         isSelected: !!option.isSelected,
//       })),
//     })),
//   };
// };

const transformStepToRecoilState = (step) => {
  return {
    id: step.id,
    stepNumber: step.stepNumber,
    totalSteps: step.totalSteps,
    finalStepTitle: step.finalStepTitle,
    conditions: step.conditions.map((condition) => ({
      conditionType: condition.conditionType,
      id: condition.id,
      finalConditionName: condition.finalConditionName,
      options: condition.options.map((option) => {
        const optionObject = {
          id: option.id,
          selectedOptionName: option.selectedOptionName,
          isSelected: !!option.isSelected,
        };
        if (option.notSelectedOptionName !== null) {
          optionObject.notSelectedOptionName = option.notSelectedOptionName;
        }
        return optionObject;
      }),
    })),
  };
};

export default transformStepToRecoilState;
