import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import HomePageContainer from "../../../home/components/home/shared/HomePageContainer";
import AllModels from "./allModels/AllModels";
import SeriesFilter from "./seriesFilter/SeriesFilter";
import ModelHeading from "./modelHeading/ModelHeading";
import SeriesFilterMobile from "./seriesFilter/SeriesFilterMobile";
import { useParams } from "react-router-dom";
import { QUERY_GET_SELLINGPRODUCTS_BY_BRAND } from "../../../../graphql/Query";
import { useRecoilValue } from "recoil";
import { modelSeriesAtom } from "../../../../store/atoms/selling/modelProducts/modelProductAtom";
import { useQuery } from "@apollo/client";

const AllBrandModels = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { categoryId, brandId } = useParams();
  const seriesId = useRecoilValue(modelSeriesAtom);
  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_SELLINGPRODUCTS_BY_BRAND,
    {
      variables: { brandId, categoryId, seriesId },
      skip: !categoryId && !brandId,
    }
  );

  useEffect(() => {
    if (seriesId && brandId && categoryId && !loading) {
      refetch({ brandId, categoryId, seriesId });
    }
  }, [seriesId, brandId, categoryId, refetch, loading]);

  return (
    <ResponsiveContainer
      sx={{
        minHeight: "calc(100vh - 137px)",
        minWidth: "300px",
      }}
    >
      {isSmallScreen && (
        <>
          <SeriesFilterMobile />
        </>
      )}
      <Box
        margin={isSmallScreen ? "1rem 0" : "3rem 0"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={isSmallScreen ? "1rem" : "2rem"}
      >
        <HomePageContainer
          sx={{
            margin: isSmallScreen ? "0 1rem" : "0",
          }}
        >
          <ModelHeading heading={data?.getProductByBrand[0]?.categoryName} />
        </HomePageContainer>
        <Box
          sx={{
            margin: isSmallScreen ? "0 1rem" : "0",
            display: "flex",
            gap: "2rem",
          }}
        >
          {!isSmallScreen && (
            <Box
              flexBasis={"25%"}
              sx={{ margin: isSmallScreen ? "0 1rem" : "0" }}
            >
              <SeriesFilter />
            </Box>
          )}

          <Box
            sx={{ margin: isSmallScreen ? "0" : "0", height: "100%" }}
            flexBasis={isSmallScreen ? "100%" : "75%"}
          >
            {data?.getProductByBrand?.length > 0 && (
              <AllModels models={data?.getProductByBrand} />
            )}
          </Box>
        </Box>
      </Box>
    </ResponsiveContainer>
  );
};

export default AllBrandModels;
