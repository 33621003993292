import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import SellingBreadCrumb from "../../shared/SellingBreadCrumb";

const generateBreadcrumbs = (pathname, search) => {
  const pathnames = pathname.split("/").filter((x) => x);
  const searchParams = new URLSearchParams(search);

  // Extract only 'categoryId' if it exists
  const categoryIdParam = searchParams.get("categoryId")
    ? `?categoryId=${searchParams.get("categoryId")}`
    : "";

  const breadcrumbs = pathnames.map((value, index) => {
    const to = `/${pathnames.slice(0, index + 1).join("/")}`;

    // Add 'categoryId' query only for the first and last breadcrumb
    const isFirst = index === 0;
    const isLast = index === pathnames.length - 1;

    return {
      text: value.replace(/-/g, " ").replace(/(^\w)/, (c) => c.toUpperCase()), // Clean text
      to: isFirst || isLast ? `${to}${categoryIdParam}` : to, // Add query only to first and last
    };
  });

  // Explicitly ensure Home has no query params
  return [{ text: "Home", to: "/" }, ...breadcrumbs];
};

const ModelHeading = ({ heading }) => {
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
    >
      <Box>
        <CustomTypography
          varaint={"subtitle1"}
          sx={{
            fontWeight: "600",
            fontSize: isSmallScreen ? "1.25rem" : "1.5rem",
          }}
        >
          Sell Your {heading}
        </CustomTypography>
      </Box>
      <Box>
        <CustomTypography>
          {/* <SellingBreadCrumb generateBreadcrumbs={generateBreadcrumbs} /> */}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default ModelHeading;
