import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { selectedAssessmentState } from "../../../../../store/atoms/selling/sellingForms/sellingForms";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackbarNotifier from "../../../../shared/hooks/useSnackbarNotifier";
import transformStepToRecoilState from "../../../../../utils/transformStepToRecoilState";

const Step3All = ({ step }) => {
  const [selectedCondition, setSelectedCondition] = useRecoilState(
    selectedAssessmentState
  );
  const showSnackbar = useSnackbarNotifier();
  const navigate = useNavigate();
  const location = useLocation();
  const productName = new URLSearchParams(location.search).get("productName");
  const productId = new URLSearchParams(location.search).get("pId");
  const productPrice = new URLSearchParams(location.search).get("productPrice");
  const imageURL = new URLSearchParams(location.search).get("imageUrl");
  const cId = new URLSearchParams(location.search).get("cId");
  const cn = new URLSearchParams(location.search).get("cn");
  let stepNo = new URLSearchParams(location.search).get("stepNo");
  const [localSelections, setLocalSelections] = useState(
    step.conditions.reduce((acc, condition) => {
      acc[condition.id] = condition.selectedOption?.id || "";
      return acc;
    }, {})
  );

  useEffect(() => {
    if (step && selectedCondition.length > 0) {
      const transformedStep = transformStepToRecoilState(step);
      setSelectedCondition((prevState) => {
        const stepExists = prevState.some(
          (existingStep) => existingStep.id === transformedStep.id
        );

        if (stepExists) {
          return prevState;
        } else {
          return [...prevState, transformedStep];
        }
      });
    }
  }, [step]);

  useEffect(() => {
    if (
      selectedCondition.length === 0 &&
      selectedCondition?.length < step?.stepNumber
    ) {
      navigate(
        `/sell/calculator/page?pId=${productId}&productName=${productName}&productPrice=${productPrice}&imageUrl=${imageURL}&cId=${cId}&cn=${cn}&stepNo=${1}`
      );
    }
  }, [selectedCondition, step?.stepNumber, navigate, cId]);

  useEffect(() => {
    const step3Data = selectedCondition.find((s) => s.id === step.id);
    if (step3Data) {
      step3Data.conditions.forEach((condition) => {
        condition.options.forEach((option) => {
          if (option.isSelected) {
            setLocalSelections((prev) => ({
              ...prev,
              [condition.id]: option.id,
            }));
          }
        });
      });
    }
  }, [selectedCondition, step]);

  const handleOptionChange = (conditionId, value) => {
    setLocalSelections((prev) => ({
      ...prev,
      [conditionId]: value,
    }));
  };

  const updateConditionOptions = (condition) => {
    return {
      ...condition,
      options: condition.options.map((option, index) =>
        localSelections[condition.id] === option.id
          ? { ...option, isSelected: true }
          : option
      ),
    };
  };

  const updateConditions = (stepState) => {
    if (stepState.id === step.id) {
      return {
        ...stepState,
        conditions: stepState.conditions.map(updateConditionOptions),
      };
    }
    return stepState;
  };

  const handleNext = () => {
    const unselectedConditions = step.conditions.filter(
      (condition) => !localSelections[condition.id]
    );

    if (unselectedConditions.length > 0) {
      showSnackbar("Please select an option for every field.", "warning");
      return;
    }

    setSelectedCondition((prevState) => prevState.map(updateConditions));
    navigate(
      `/sell/calculator/page?pId=${productId}&productName=${productName}&productPrice=${productPrice}&imageUrl=${imageURL}&cId=${cId}&cn=${cn}&stepNo=${++stepNo}`
    );
  };

  if (selectedCondition && selectedCondition.length < step.stepNumber) {
    return <>Loading....</>;
  }

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", tabletMedium: "row" }}
        gap={"1rem"}
      >
        {step.conditions.map((condition) => (
          <Box
            key={condition.id}
            sx={{
              width: "100%",
              minHeight: { xs: "100%", desktop: "250px" },
              backgroundColor: "#F4F6F9",
              backgroundImage:
                "linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "1rem",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box>
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{
                    color: "#214C7B",
                    fontSize: {
                      xs: "1rem",
                      laptop: "1.125rem",
                      desktop: "1.25rem",
                    },
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  {condition.title}
                </CustomTypography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", desktop: "row" }}
                gap={"0.5rem"}
              >
                <Box
                  flexBasis={{ xs: "none", desktop: "30%" }}
                  sx={{
                    justifyContent: "center",
                    width: { xs: "100%", desktop: "100%" },
                    height: {
                      xs: "125px",
                      tabletMedium: "140px",
                      laptop: "160px",
                      desktop: "180px",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`https://d3473cyq46ezul.cloudfront.net/${condition.image}`}
                    style={{
                      width: { xs: "60%", desktop: "100%" },
                      height: "100%",
                    }}
                  />
                </Box>
                <Box
                  flexBasis={{ xs: "none", desktop: "70%" }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "center",
                      tabletMedium: "flex-start",
                    },
                  }}
                >
                  <Box>
                    <RadioGroup
                      value={localSelections[condition.id] || ""}
                      onChange={(e) =>
                        handleOptionChange(condition.id, e.target.value)
                      }
                    >
                      {condition?.options.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          value={option.id}
                          control={
                            <Radio
                              sx={{
                                color: "#214C7B",
                                "&.Mui-checked": {
                                  color: "#214C7B",
                                },
                                "& .MuiSvgIcon-root": {
                                  fontSize: { xs: 15, laptop: 20 },
                                },
                                "&:hover": {
                                  backgroundColor: "rgba(33, 76, 123, 0.04)",
                                },
                              }}
                            />
                          }
                          label={
                            <CustomTypography
                              variant="body2"
                              sx={{
                                color: "#214C7B",
                                fontSize: {
                                  xs: "0.75rem",
                                  tabletMedium: "0.80rem",
                                  laptop: "0.875rem",
                                },
                              }}
                            >
                              {option.label}
                            </CustomTypography>
                          }
                          sx={{
                            marginBottom: { xs: "0.1rem", laptop: "0.5rem" },
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="outlined"
          onClick={handleNext}
          sx={{
            backgroundColor: "#214C7B",
            borderRadius: "10px",
            color: "white",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#214C7B",
            },
          }}
        >
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Next <KeyboardArrowRight />
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default Step3All;
