import React, { useState } from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import SellingSearchBar from "./SellingSearchBar";
import ExchangePhone from "../../../../../Images/selling/sellingBrandsPage/ExchangePhone.png";
import BrandCarousel from "./BrandCarousel";
import SellingBreadCrumb from "../../shared/SellingBreadCrumb";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ALL_BRANDS_BY_CAT } from "../../../../../graphql/Query";

const generateBreadcrumbs = (pathname, search) => {
  const pathnames = pathname.split("/").filter((x) => x);

  const breadcrumbs = pathnames.map((value, index) => {
    const to = `/${pathnames.slice(0, index + 1).join("/")}${search || ""}`; // Include search in the link
    return {
      text: value.replace(/-/g, " ").replace(/(^\w)/, (c) => c.toUpperCase()), // Clean text
      to,
    };
  });

  // If search exists, it doesn't alter the breadcrumb text but appends to the link
  return [{ text: "Home", to: `/${search || ""}` }, ...breadcrumbs];
};

const SellingBrandsSection = () => {
  const location = useLocation();
  const { categoryId } = useParams();

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_ALL_BRANDS_BY_CAT,
    {
      variables: {
        category: categoryId,
      },
      skip: !categoryId,
    }
  );

  const firstObject = data?.getAllBrandByCategory[0];

  const extracted = location.pathname
    .replace(/\/sell(-old)?-/, "")
    .replace(/-/g, " ");

  const result =
    extracted === "other accessories" ? extracted : `your ${extracted}`;

  const capitalize = (str) =>
    str.replace(/\b\w/g, (char) => char.toUpperCase());

  const finalResult =
    extracted === "other accessories"
      ? capitalize(result)
      : `your ${capitalize(extracted)}`;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:766px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  return (
    <>
      <Box>
        <Box sx={{ position: "relative" }}>
          <Box display={"flex"} sx={{ position: "relative" }}>
            <Box
              flexBasis={
                isMobileScreen ? (isSmallScreen ? "100%" : "50%") : "40%"
              }
              display={"flex"}
              flexDirection={"column"}
              gap={isSmallScreen ? "0.75rem" : "1.5rem"}
            >
              <Box>
                <CustomTypography
                  variant={"h4"}
                  sx={{
                    fontWeight: "600",
                    color: "#0A2544",
                    fontSize: { xs: "1.5rem", laptop: "2.25rem" },
                  }}
                >
                  Sell {firstObject?.category} Online
                </CustomTypography>
              </Box>
              <Box>
                <SellingSearchBar />
              </Box>
            </Box>
            <Box
              display={isSmallScreen ? "none" : "flex"}
              flexBasis={
                isMobileScreen ? (isSmallScreen ? "100%" : "50%") : "60%"
              }
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              height={"370px"}
              zIndex={-10}
            >
              <img
                src={ExchangePhone}
                style={{
                  width: isMobileScreen ? "100%" : "60%",
                  height: "100%",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              position: isSmallScreen ? "relative" : "absolute",
              bottom: isSmallScreen ? "0" : "-80px",
              width: "100%",
              marginTop: isSmallScreen ? "1rem" : "0rem",
            }}
          >
            <Box>
              {data?.getAllBrandByCategory?.length > 0 && (
                <BrandCarousel brands={data?.getAllBrandByCategory} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SellingBrandsSection;
