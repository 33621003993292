import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import DiscountFunction from "../../../../../utils/DiscountFunction";
import { Favorite } from "@mui/icons-material";

const TrendingGadgetCard = ({ img, title, price, mrp }) => {
  const theme = useTheme();

  return (
    <Box
      className={"trending-gadget-card"}
      display={"flex"}
      sx={{
        flexDirection: "column",
        gap: "1rem",
        minHeight: "120px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        background:
          "linear-gradient(180deg, rgba(94, 92, 92, 0.5) 0%, rgba(255, 255, 255, 0.6) 79%)",
        borderRadius: "0.75rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "1rem 1rem 0 1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={`${`https://d3473cyq46ezul.cloudfront.net/`}${img.md}`}
          style={{
            height: theme.breakpoints.down("tabletMedium") ? "200px" : "250px",
            width: theme.breakpoints.down("tabletMedium") ? "70%" : "80%",
            objectFit: "cover",
            borderRadius: "0.5rem",
          }}
        />
      </Box>
      <Box sx={{ padding: "0 1rem" }}>
        <CustomTypography
          variant={"subtitle1"}
          sx={{
            fontWeight: "600",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "1.5rem",
            minHeight: "3rem",
          }}
        >
          {title}
        </CustomTypography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
          margin: "0 1rem",
          borderRadius: "0.5rem",
          background:
            "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(235, 243, 255, 0.49) 49%, rgba(255, 255, 255, 0) 100%)",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <Box
          sx={{
            padding: "0.5rem 0",
            borderRadius: "0.5rem",
          }}
        >
          <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
            Price
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
            ₹ {price}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              fontWeight: "600",
              textDecoration: "line-through",
              color: "#999494",
            }}
          >
            ₹ {mrp}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              fontWeight: "600",
              color: "#FF6B6B",
            }}
          >
            {DiscountFunction(price, mrp)}% OFF
          </CustomTypography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        gap={"1rem"}
        justifyContent={"space-evenly"}
        sx={{ margin: "0 1rem 1rem 1rem" }}
      >
        <Button
          sx={{
            backgroundColor: "white",
            color: "#214C7B",
            textTransform: "none",
            border: "1.5px solid #214C7B",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
            whiteSpace: "nowrap",
            "&.Mui-disabled": {
              backgroundColor: "rgb(254, 123, 121)",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "white",
              border: "1.5px solid #214C7B",
            },
          }}
        >
          <Favorite />
        </Button>
        <Button
          sx={{
            backgroundColor: "white",
            color: "#214C7B",
            textTransform: "none",
            border: "1.5px solid #214C7B",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
            whiteSpace: "nowrap",
            "&.Mui-disabled": {
              backgroundColor: "rgb(254, 123, 121)",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "white",
              border: "1.5px solid #214C7B",
            },
          }}
        >
          <CustomTypography variant={"subtitle2"}>Add To Cart</CustomTypography>
        </Button>
        <Button
          sx={{
            backgroundColor: "white",
            color: "#214C7B",
            textTransform: "none",
            border: "1.5px solid #214C7B",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
            whiteSpace: "nowrap",
            "&.Mui-disabled": {
              backgroundColor: "rgb(254, 123, 121)",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "white",
              border: "1.5px solid #214C7B",
            },
          }}
        >
          <CustomTypography variant={"subtitle2"}>Buy</CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default TrendingGadgetCard;
