import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ProductQuoteCard = ({ sellingOrderData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(
      `/sell/calculator/page?pId=${sellingOrderData.productId}&productName=${
        sellingOrderData.productName
      }&productPrice=${sellingOrderData.productPrice}&imageUrl=${
        sellingOrderData.productImage
      }&cId=${sellingOrderData.categoryId}&cn=${
        sellingOrderData.categoryName
      }&stepNo=${1}`
    );
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <CustomTypography
          variant={"subtitle1"}
          sx={{
            fontWeight: "700",
            fontSize: {
              xs: "1.125rem",
              desktop: "1.25rem",
            },
            color: "#214C7B",
          }}
        >
          Device Details
        </CustomTypography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={isSmallScreen ? "column" : "row"}
        sx={{
          borderRadius: "15px",
          background: "linear-gradient(116deg, #F6EDED 23.57%, #DFE8EA 77.15%)",
          boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          minHeight: "250px",
          padding: "1rem",
          gap: "1rem",
          minHeight: "250px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            flexBasis: isSmallScreen ? "100%" : "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={`https://d3473cyq46ezul.cloudfront.net/${sellingOrderData.productImage}`}
            alt={sellingOrderData.productName}
            sx={{
              width: {
                xs: "125px",
                tablet: "150px",
                tabletMedium: "175px",
                laptop: "200px",
              },
              height: {
                xs: "125px",
                tablet: "150px",
                tabletMedium: "175px",
                laptop: "200px",
              },
              objectFit: "contain",
            }}
          />
        </Box>
        <Box
          sx={{
            flexBasis: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Box>
            <CustomTypography
              variant={"subtitle1"}
              sx={{
                fontWeight: "700",
                fontSize: {
                  xs: "0.95rem",
                  tabletMedium: "1rem",
                  laptop: "1.125rem",
                },
              }}
            >
              {sellingOrderData.productName}
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography
              varinat={"subtitle2"}
              sx={{
                fontWeight: "600",
                color: "#069BAA",
                fontSize: {
                  xs: "0.825rem",
                  tabletMedium: "0.875rem",
                  laptop: "0.95rem",
                  desktop: "1rem",
                },
              }}
            >
              Quoted Price
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography
              variant={"subtitle2"}
              sx={{
                fontWeight: "600",
                fontSize: {
                  xs: "0.875rem",
                  laptop: "0.95rem",
                  desktop: "1.125rem",
                },
              }}
            >
              Price <span>₹ {sellingOrderData.totalAmount}</span>
            </CustomTypography>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                borderRadius: "10px",
                border: "2px solid #214C7B",
                background: "#FFF",
                "&:hover": {
                  background: "#FFF",
                  border: "2px solid #214C7B",
                },
              }}
              onClick={handleNavigate}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  color: "#214C7B",
                  fontSize: {
                    xs: "0.825rem",
                    tabletMedium: "0.875rem",
                    laptop: "0.95rem",
                    desktop: "1rem",
                  },
                }}
              >
                Recalculation <ArrowForwardIos sx={{ fontSize: "1rem" }} />
              </CustomTypography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductQuoteCard;
