import React, { useState } from "react";
import swal from "sweetalert";
import { Table, Button, Modal, Card, Form } from "react-bootstrap";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_YOUTUBE_REVIEWS } from "../../graphql/Query";
import { MUTATION_UPDATE_YOUTUBE_URL } from "../../graphql/Mutations";
import GoogleAuthButton from "./GoogleAuthButton";

const AllYoutubeReviews = () => {
  const [lgShow, setLgShow] = useState(false);
  const [formData, setFormData] = useState({
    url: "",
    youtubeId: "",
  });

  const [updateYoutubeURL] = useMutation(MUTATION_UPDATE_YOUTUBE_URL, {
    refetchQueries: [QUERY_GET_YOUTUBE_REVIEWS, QUERY_GET_YOUTUBE_REVIEWS],
  });

  const { data: getAllYoutube, loading: Loading } = useQuery(
    QUERY_GET_YOUTUBE_REVIEWS
  );

  const handleEdit = (youtube) => {
    // console.log(youtube);
    setFormData({ ...formData, url: youtube.url, youtubeId: youtube.id });
    setLgShow(true);
  };

  const handleSubmit = () => {
    // console.log(formData.youtubeId);
    updateYoutubeURL({
      variables: {
        youtubeId: formData.youtubeId,
        url: formData.url,
      },
    })
      .then(() => {
        swal({
          title: "Successfull!!!",
          text: "Youtube link updated successfully",
          icon: "success",
        });
        setLgShow(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div>
        <Table
          responsive
          striped
          bordered
          hover
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            display: "block",
            height: "600px",
            width: "100%",
          }}
        >
          <thead className="table-head">
            <tr>
              <th>s.no.</th>
              <th>Youtube Url</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {!Loading &&
              getAllYoutube &&
              getAllYoutube.getAllYoutube.length > 0 &&
              getAllYoutube.getAllYoutube.map((youtube, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{youtube.url}</td>
                  <td>
                    <Button
                      style={{ fontSize: "11px" }}
                      onClick={() => handleEdit(youtube)}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <GoogleAuthButton />
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Youtube Url</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ width: "46rem" }} className="mx-auto d-block">
            <Card.Body>
              <Form>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>Youtube URL</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={formData.url}
                    onChange={(e) =>
                      setFormData({ ...formData, url: e.target.value })
                    }
                  />
                  <Form.Text className="text-muted">
                    Enter the Youtube URL to in any of dthe below format. <br />
                    1.https://www.youtube.com/watch?v=Eza-Z_nAQOI
                    <br />
                    2.https://youtu.be/Eza-Z_nAQOI?si=V9r-RVmz6BoLna3c
                    <br />
                    3.Eza-Z_nAQOI
                  </Form.Text>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllYoutubeReviews;
