import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { selectedAssessmentState } from "../../../../../store/atoms/selling/sellingForms/sellingForms";
import { useRecoilState } from "recoil";
import zIndex from "@mui/material/styles/zIndex";
import { MUTATION_ADD_SELLING_ORDER } from "../../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import useSnackbarNotifier from "../../../../shared/hooks/useSnackbarNotifier";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "white",
    color: "#214C7B",

    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    borderRadius: 8,
    padding: "16px",
    boxShadow: theme.shadows[2],
  },
  [`& .MuiTooltip-arrow`]: {
    "&::before": {
      backgroundColor: "white",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    },

    color: "white",
    fontSize: 40,
    transform: "scale(1.5)",
  },
}));

const SellingCondition = ({ index, condition, step, productData }) => {
  const navigate = useNavigate();
  const showSnackbar = useSnackbarNotifier();
  const [selectedCondition, setSelectedCondition] = useRecoilState(
    selectedAssessmentState
  );

  const [addSellingOrder, { loading: sellingOrderLoading }] = useMutation(
    MUTATION_ADD_SELLING_ORDER
  );
  const [selectedOption, setSelectedOption] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  useEffect(() => {
    if (selectedCondition && selectedCondition.length > 0) {
      const currentStep = selectedCondition.find(
        (selectedStep) => selectedStep.id === step.id
      );

      if (currentStep) {
        const currentCondition = currentStep.conditions.find(
          (c) => c.id === condition.id
        );

        if (currentCondition) {
          const selectedOpt = currentCondition.options.find(
            (opt) => opt.isSelected
          );
          if (selectedOpt) {
            setSelectedOption(selectedOpt.id);
          }
        }
      }
    }
  }, [selectedCondition, condition.id, step.id]);

  const handleOptionChange = (event) => {
    const selectedOptionId = event.target.value;
    setSelectedOption(selectedOptionId);

    setSelectedCondition((prevState) => {
      const updatedState = prevState.map((currentStep) => {
        if (currentStep.id !== step.id) {
          return currentStep;
        }

        const updatedConditions = currentStep.conditions.map((cond) => {
          if (cond.id !== condition.id) {
            return cond;
          }

          const updatedOptions = cond.options.map((opt) => ({
            ...opt,
            isSelected: opt.id === selectedOptionId,
          }));

          return {
            ...cond,
            options: updatedOptions,
          };
        });

        return {
          ...currentStep,
          conditions: updatedConditions,
        };
      });

      return updatedState;
    });
  };

  const handleRecycle = async (modalcontent, productData) => {
    try {
      const res = await addSellingOrder({
        variables: {
          selectedSellingData: selectedCondition,
          productData: productData,
          action: modalcontent,
        },
      });

      navigate(
        `/sell/quote/details?orderId=${res.data.addSellingOrder.sellingOrderId}`
      );
    } catch (error) {
      showSnackbar("Failed to process order. Please try again.", "error");
      console.error("Error processing order:", error);
    }
  };

  const renderTooltipContent = (modalContent, productData) => (
    <Box sx={{ p: 1 }}>
      <>
        <CustomTypography variant="subtitle2" sx={{ color: "#214C7B", mb: 2 }}>
          If your device is not switching ON we won't be able to use it again.
        </CustomTypography>
        <CustomTypography variant="subtitle2" sx={{ color: "#214C7B", mb: 2 }}>
          But Still if you want to SELL it click on sell now.
        </CustomTypography>

        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: "#214C7B",
            "&:hover": {
              backgroundColor: "#1a3a5f",
            },
          }}
          onClick={() => handleRecycle(modalContent, productData)}
        >
          Sell Now
        </Button>
      </>
    </Box>
  );
  return (
    <Box
      display={"flex"}
      flexDirection={isSmallScreen ? "row" : "column"}
      gap={"1rem"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={{ xs: "25%", tablet: "40%" }}
        gap={"1rem"}
        sx={{
          justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        {!isSmallScreen && (
          <Box>
            <CustomTypography
              variant={"subtitle2"}
              sx={{ fontWeight: "400", fontSize: "1.15rem", color: "black" }}
            >
              {condition.title}
            </CustomTypography>
          </Box>
        )}

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: isSmallScreen ? "80px" : "120px",
            height: isSmallScreen ? "80px" : "120px",
            borderRadius: "50%",
            backgroundColor: "white",
            flexShrink: 0,
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
          }}
        >
          <img
            src={`https://d3473cyq46ezul.cloudfront.net/${condition.image}`}
            alt={condition.title}
            style={{
              width: isSmallScreen ? "60px" : "80px",
              height: isSmallScreen ? "60px" : "80px",
            }}
          />
        </Box>

        {isSmallScreen && (
          <Box>
            <CustomTypography
              variant={"subtitle2"}
              sx={{
                fontWeight: "400",
                fontSize: "0.75rem",
                color: "black",
                textAlign: "center",
              }}
            >
              {condition.title}
            </CustomTypography>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        flexBasis={{ xs: "75%", tablet: "60%" }}
        flexDirection="column"
        gap="0.75rem"
        width={"100%"}
        flexGrow={1}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          sx={{ width: "100%" }}
        >
          <CustomTypography
            variant={"subtitle2"}
            sx={{ color: "#214C7B", fontSize: "0.90rem", fontWeight: "700" }}
          >
            {++index}. {condition.description}
          </CustomTypography>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              color: "#214C7B",
              textAlign: { tabletMedium: "center", laptop: "left" },
            }}
          >
            {condition.subtitle}
          </CustomTypography>
        </Box>

        <RadioGroup
          value={selectedOption}
          onChange={handleOptionChange}
          name={`condition-${index}`}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          {condition.options.map((option) => (
            <Box
              key={option.id}
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <FormControlLabel
                value={option.id}
                control={
                  <Radio
                    sx={{
                      color: "#214C7B",
                      "&.Mui-checked": {
                        color: "#214C7B",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: isSmallScreen ? 15 : 20,
                      },
                    }}
                  />
                }
                label={
                  <CustomTypography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "400",
                      fontSize: isSmallScreen ? "0.75rem" : "0.9rem",
                      color: "#214C7B",
                    }}
                  >
                    {option.label}
                  </CustomTypography>
                }
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  flexGrow: 1,
                  border: "1px solid #214C7B",
                  borderRadius: "8px",
                  padding: isSmallScreen ? "0.25rem 0.5rem" : "0.5rem 1rem",
                  margin: "0 auto",
                  backgroundColor:
                    selectedOption === option.id ? "#f0f8ff" : "transparent",
                  "&:hover": { backgroundColor: "#f0f8ff" },
                }}
              />

              {option.label === "No (Recycle)" && option.isRedirect && (
                <CustomTooltip
                  title={renderTooltipContent(option.redirectTo, productData)}
                  placement={isSmallScreen ? "bottom" : "right"}
                  arrow
                  open={selectedOption === option.id}
                ></CustomTooltip>
              )}
            </Box>
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default SellingCondition;
