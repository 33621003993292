import {
  Box,
  Button,
  FormControl,
  Input,
  TextareaAutosize,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableBody,
} from "@mui/material";
import swal from "sweetalert";
import React, { useState } from "react";
import CustomTypography from "../../../muicomponents/shared/CustomTypography";
import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_GET_ALL_ASSESSMENTS,
  QUERY_GET_All_CATEGORY_TYPE,
} from "../../../graphql/Query";
import { MUTATION_ADD_ASSESSMENT } from "../../../graphql/Mutations";
import { FaEye } from "react-icons/fa";

const AddAssessment = () => {
  const [addAssessment, { loading: addAssessmentLoading }] = useMutation(
    MUTATION_ADD_ASSESSMENT,
    { refetchQueries: [QUERY_GET_ALL_ASSESSMENTS] }
  );

  const { data, loading, error } = useQuery(QUERY_GET_ALL_ASSESSMENTS);
  const [assessment, setAssessment] = useState({
    category: "",
    name: "",
    totalSteps: 0,
    description: "",
  });

  const handleAddAssesment = async () => {
    try {
      const { data } = await addAssessment({
        variables: {
          category: assessment.category,
          name: assessment.name,
          totalSteps: assessment.totalSteps,
          description: assessment.description,
        },
      });
      swal({
        title: "Success",
        text: data.addAssessment.message,
        icon: "success",
      });
      setAssessment({ category: "", name: "", totalSteps: 0, description: "" });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setAssessment({
      ...assessment,
      [name]: name === "totalSteps" ? Number(value) : value,
    });
  };
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery(QUERY_GET_All_CATEGORY_TYPE, {
    variables: { categoryType: "sell" },
  });

  if (categoryLoading) {
    return <>Loading...</>;
  }
  if (categoryError) {
    return <>Error...</>;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ margin: "3rem 0" }}
    >
      <Box
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", laptop: "inherit" },
          gap: "1rem",
          padding: "1rem",
          borderRadius: "0.5rem",
          gap: "1rem",
        }}
      >
        <Box>
          <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
            Add Assesment
          </CustomTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center",
            width: { laptop: "400px", xs: "100%" },
            minHeight: "200px",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Assessment Name"
              variant="outlined"
              name="name"
              value={assessment.name}
              onChange={handleInputChange}
              size="small"
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextareaAutosize
              placeholder="Type Description here...."
              minRows={2}
              style={{ width: "100%", padding: "0.5rem" }}
              value={assessment.description}
              name={"description"}
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                select category type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="category"
                value={assessment.category}
                label="select category state"
                size="small"
                onChange={handleInputChange}
                sx={{ width: "100%" }}
              >
                {categoryData.getAllCategoryByType.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Total Steps"
              type="number"
              variant="outlined"
              name="totalSteps"
              value={assessment.totalSteps === 0 ? "" : assessment.totalSteps}
              onChange={handleInputChange}
              size="small"
              sx={{ width: "100%" }}
            />
          </Box>

          <Box>
            <Button
              type="submit"
              variant="outlined"
              onClick={handleAddAssesment}
            >
              Add Assesment
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          overflowX: "hidden",
          marginTop: "2rem",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto",
          }}
        >
          <TableHead className="table-head">
            <TableRow>
              <TableCell>Sl No.</TableCell>
              <TableCell>Assessment Name</TableCell>
              <TableCell>Assessment Description</TableCell>
              <TableCell>category</TableCell>
              <TableCell>Total Steps</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.getAllAssessments.map((assessment, index) => (
              <TableRow key={assessment.id}>
                <TableCell align="right">{index + 1}</TableCell>
                <TableCell align="right" sx={{ wordBreak: "break-word" }}>
                  {assessment?.name}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ wordBreak: "break-word", maxWidth: "300px" }}
                >
                  {assessment?.description}
                </TableCell>
                <TableCell align="center" sx={{ textAlign: "center" }}>
                  {assessment?.category?.name}
                </TableCell>
                <TableCell align="right">{assessment.totalSteps}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AddAssessment;
