import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Box } from "@mui/material";

const ConditionOptionTitle = ({ conditions }) => {
  return (
    <>
      {conditions.map((condition) => (
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            color: "black",
            fontSize: {
              xs: "0.825rem",
              tabletMedium: "0.875rem",
              laptop: "0.95rem",
              desktop: "1rem",
            },
          }}
        >
          {condition.finalConditionName}
          {"  "}
          <span style={{ color: "#214C7B", fontWeight: "700" }}>
            {condition.optionTitle}
          </span>
        </CustomTypography>
      ))}
    </>
  );
};

export default ConditionOptionTitle;
