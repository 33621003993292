import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";

const StepTitle = ({ title, subtitle, subtitle2 }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      gap={"0.5rem"}
      sx={{
        borderLeft: "6px solid #214C7B",
        paddingLeft: "1rem",
        height: "100%",
      }}
    >
      <Box sx={{ height: "100%" }}>
        <CustomTypography
          variant={"subtitle1"}
          sx={{
            fontWeight: "600",
            fontSize: {
              xs: "1.25rem",
              tabletMedium: "1.5rem",
              laptop: "1.75rem",
            },
            fontweight: "700",
            color: "#214C7B",
          }}
        >
          {title}
        </CustomTypography>
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            fontweight: "700",
            fontSize: { xs: "1rem", tabletMedium: "1.1rem", laptop: "1.25rem" },
            color: "#214C7B",
          }}
        >
          {subtitle}
        </CustomTypography>
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            fontWeight: "500",
            fontSize: {
              xs: "0.9rem",
              tabletMedium: "0.95rem",
              laptop: "1rem",
            },
            color: "#54B0BA",
          }}
        >
          {subtitle2}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default StepTitle;
