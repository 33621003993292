import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../../muicomponents/shared/CustomTypography";
import {
  QUERY_GET_ALL_CONDITIONS,
  QUERY_GET_ALL_SELLING_OPTIONS,
} from "../../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import { FaEye } from "react-icons/fa";
import { MUTATION_ADD_SELLING_OPTION } from "../../../graphql/Mutations";
import swal from "sweetalert";
import EditSellingOption from "./modals/EditSellingOption";
const AddSellingOption = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editOption, setEditOption] = useState(null);
  const [sellingOption, setSellingOption] = useState({
    label: "",
    score: 0,
    action: "",
    scoreType: "",
    condition: "",
    priority: 0,
    title: "",
    selectedOptionName: "",
    description: "",
  });

  const {
    data: allSellingOptions,
    loading: allSellingOptionsLoading,
    error: allSellingOptionsError,
  } = useQuery(QUERY_GET_ALL_SELLING_OPTIONS);

  const [addSellingOption, { loading: addSellingOptionLoading }] = useMutation(
    MUTATION_ADD_SELLING_OPTION,
    { refetchQueries: [QUERY_GET_ALL_SELLING_OPTIONS] }
  );
  const {
    data: allSellingConditions,
    loading: allSellingConditionsLoading,
    error: allSellingConditionsError,
  } = useQuery(QUERY_GET_ALL_CONDITIONS);

  const handleAddOption = async () => {
    try {
      const finalOption = {
        selectedOptionName: sellingOption.selectedOptionName,
        action: sellingOption.action,
        label: sellingOption.label,
        score: sellingOption.score,
        scoreType: sellingOption.scoreType,
        condition: sellingOption.condition,
        priority: sellingOption.priority,
        title: sellingOption.title,
        description: sellingOption.description,
      };

      const { data } = await addSellingOption({
        variables: {
          sellingOption: finalOption,
        },
      });

      swal({
        title: "Success",
        text: data.addSellingOption.message,
        icon: "success",
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setSellingOption({
        label: "",
        score: 0,
        scoreType: "",
        condition: "",
        priority: 0,
        title: "",
        description: "",
      });
    }
  };

  const handleModalOpen = (option) => {
    setEditOption(option);
    setModalOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSellingOption({
      ...sellingOption,
      [name]: name === "priority" || name === "score" ? Number(value) : value,
    });
  };

  if (allSellingOptionsLoading) {
    return <>Loading....</>;
  }

  if (allSellingOptionsError) {
    return <>Error....</>;
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ margin: "3rem 0" }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            gap: "1rem",
            width: { xs: "100%", laptop: "inherit" },
          }}
        >
          <Box>
            <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
              Add Option
            </CustomTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: { laptop: "400px", xs: "100%" },
              minHeight: "200px",
              backgroundColor: "white",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Option Label"
                variant="outlined"
                name="label"
                value={sellingOption.label}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Option Title"
                variant="outlined"
                name="title"
                value={sellingOption.title}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextareaAutosize
                placeholder="Type Description here...."
                minRows={2}
                style={{ width: "100%", padding: "0.5rem" }}
                name="description"
                value={sellingOption.description}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Selected Option Name"
                variant="outlined"
                name="selectedOptionName"
                value={sellingOption.selectedOptionName}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Score (1-100)"
                variant="outlined"
                name="score"
                value={sellingOption.score || ""}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
                type="number"
                inputProps={{
                  step: "0.1",
                  min: "1",
                  max: "100",
                }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select score type
                </InputLabel>
                <Select
                  name="scoreType"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sellingOption.scoreType}
                  label="select category state"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"per"}>percent(%)</MenuItem>{" "}
                  <MenuItem value={"mul"}>multiplier(*)</MenuItem>
                  <MenuItem value={"ruppee"}>ruppee(₹)</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="increment-decrement-label">Action</InputLabel>
                <Select
                  name="action"
                  labelId="increment-decrement-label"
                  id="increment-decrement-select"
                  value={sellingOption.action}
                  label="Action"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"increment"}>Increment</MenuItem>
                  <MenuItem value={"decrement"}>Decrement</MenuItem>
                  <MenuItem value={"decrement-not-selected"}>
                    Decrement Not Selected
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select condition
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sellingOption.condition}
                  label="select category state"
                  size="small"
                  name="condition"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  {allSellingConditions?.getAllSellingConditions?.map(
                    (condition) => (
                      <MenuItem key={condition.id} value={condition.id}>
                        {condition.title} - {condition.step.stepName}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button
                type="submit"
                variant="outlined"
                onClick={handleAddOption}
              >
                Add Option
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            overflowX: "hidden",
            marginTop: "2rem",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            <TableHead className="table-head">
              <TableRow>
                <TableCell>Sl No.</TableCell>
                <TableCell>Option Title</TableCell>
                <TableCell>Option Description</TableCell>
                <TableCell>Option Label</TableCell>
                <TableCell>Score</TableCell>
                <TableCell>Score Type</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Selected Option Name</TableCell>
                <TableCell>Not Selected Option Name</TableCell>
                <TableCell>Condition Name</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSellingOptions?.getAllSellingOptions?.map((option, index) => (
                <TableRow key={option?.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell
                    sx={{ wordBreak: "break-word", minWidth: "200px" }}
                  >
                    {option?.title}
                  </TableCell>
                  <TableCell
                    sx={{ wordBreak: "break-word", minWidth: "200px" }}
                  >
                    {option?.description}
                  </TableCell>
                  <TableCell
                    sx={{ wordBreak: "break-word", minWidth: "200px" }}
                  >
                    {option?.label}
                  </TableCell>
                  <TableCell sx={{ wordBreak: "break-word" }}>
                    {option?.score}
                  </TableCell>
                  <TableCell sx={{ wordBreak: "break-word" }}>
                    {option?.scoreType}
                  </TableCell>
                  <TableCell>{option?.action}</TableCell>
                  <TableCell>
                    {option?.selectedOptionName === null ? (
                      <>N/A</>
                    ) : (
                      option?.selectedOptionName
                    )}
                  </TableCell>
                  <TableCell>
                    {option?.notSelectedOptionName === null ? (
                      <>N/A</>
                    ) : (
                      option?.notSelectedOptionName
                    )}
                  </TableCell>
                  <TableCell>{option?.condition?.title}</TableCell>

                  <TableCell
                    align="right"
                    sx={{ wordBreak: "break-word", maxWidth: "300px" }}
                  >
                    {option.priority}
                  </TableCell>
                  <TableCell align="'right">
                    <Button
                      variant={"contained"}
                      onClick={() => handleModalOpen(option)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </Box>
      </Box>
      <EditSellingOption
        option={editOption}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        conditions={allSellingConditions?.getAllSellingConditions}
      />
    </>
  );
};

export default AddSellingOption;
