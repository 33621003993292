import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import React from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import { useQuery } from "@apollo/client";
import { QUERY_GET_SELLING_PRODUCT_BY_ID } from "../../../../graphql/Query";
import ProductImage from "./productImage/ProductImage";
import ProductPart from "./productPart/ProductPart";
import ProductVariants from "./productPart/ProductVariants";

const SellingProduct = () => {
  const { productId } = useParams();
  // console.log(productId);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const { data, loading, error } = useQuery(QUERY_GET_SELLING_PRODUCT_BY_ID, {
    variables: { productId: productId },
  });

  if (loading) {
    return <>Loading...</>;
  }
  if (error) {
    return <>Error</>;
  }

  return (
    <>
      <ResponsiveContainer
        sx={{
          minHeight: "calc(100vh - 137px)",
          minWidth: "300px",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: {
              xs: "200px",
              tabletMedium: "300px",
              laptop: "350px",
              desktop: "500px",
            },
            height: {
              xs: "200px",
              tabletMedium: "300px",
              laptop: "350px",
              desktop: "500px",
            },
            top: {
              xs: "-10px",
              tabletMedium: "-60px",
              laptop: "-30px",
              desktop: "-100px",
            },
            left: {
              xs: "-20px",
              tabletMedium: "-20px",
              laptop: "-35px",
              desktop: "-60px",
            },
            zIndex: -1,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={"100%"}
            height={"100%"}
            viewBox="0 0 687 712"
            fill="none"
          >
            <path
              d="M647.5 360.001C600.718 429.501 631 596.5 317.5 666C3.99976 735.5 -60.6463 743.122 -60.6463 563.802C-60.6463 384.482 -688.763 -104.062 118.5 -64.9994C540.027 -44.602 786.5 153.501 647.5 360.001Z"
              fill="url(#paint0_linear_974_75)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_974_75"
                x1="340.5"
                y1="-132"
                x2="371.968"
                y2="711.695"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.245" stopColor="white" />
                <stop offset="0.415" stopColor="#E3E9EF" />
                <stop offset="0.625202" stopColor="#C8D2DE" />
                <stop offset="0.861209" stopColor="#90A6BD" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
        <Box
          margin={isSmallScreen ? "1rem 1rem" : "3rem 0"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          gap={isSmallScreen ? "1rem" : "2rem"}
          sx={{ zIndex: 2 }}
        >
          <Box display={"flex"}>
            <Box flexBasis={"40%"}>
              <ProductImage imageKey={data?.getSellingProductById?.image} />
            </Box>
            <Box flexBasis={"60%"} product={data?.getSellingProductById}>
              <ProductPart product={data?.getSellingProductById} />
            </Box>
          </Box>
          {isSmallScreen && (
            <Box>
              <ProductVariants product={data?.getSellingProductById} />
            </Box>
          )}
        </Box>
      </ResponsiveContainer>
    </>
  );
};

export default SellingProduct;
