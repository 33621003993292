import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { Close } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MUTATION_EDIT_SELLING_OPTION } from "../../../../graphql/Mutations";
import { QUERY_GET_ALL_SELLING_OPTIONS } from "../../../../graphql/Query";

const EditSellingOption = ({ option, modalOpen, setModalOpen, conditions }) => {
  const [editSellingOption, { loading }] = useMutation(
    MUTATION_EDIT_SELLING_OPTION,
    {
      refetchQueries: [QUERY_GET_ALL_SELLING_OPTIONS],
    }
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [editOption, setEditOption] = useState({
    label: "",
    score: 0,
    action: "",
    scoreType: "",
    condition: "",
    priority: 0,
    title: "",
    selectedOptionName: "",
    notSelectedOptionName: null,
    description: "",
  });
  useEffect(() => {
    if (option) {
      setEditOption({
        label: option?.label || "",
        score: option.score || 0,
        action: option.action || "",
        scoreType: option.scoreType || "",
        condition: option.condition.id || "",
        priority: option.priority || 0,
        title: option.title || "",
        notSelectedOptionName: option.notSelectedOptionName || null,
        selectedOptionName: option.selectedOptionName || "",
        description: option.description || "",
      });
    }
  }, [option]);

  const handleEditOption = async () => {
    try {
      const finalOption = {
        label: editOption.label,
        score: editOption.score,
        action: editOption.action,
        scoreType: editOption.scoreType,
        condition: editOption.condition,
        priority: editOption.priority,
        title: editOption.title,
        selectedOptionName: editOption.selectedOptionName,
        notSelectedOptionName: editOption.notSelectedOptionName,
        description: editOption.description,
      };

      const { data } = await editSellingOption({
        variables: {
          editSellingOption: finalOption,
          editOptionId: option.id,
        },
      });
      swal({
        title: "Success",
        text: data.editSellingOption.message,
        icon: "success",
      }).then(() => {
        handleModalClose();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditOption((prevState) => {
      return {
        ...prevState,
        [name]: name === "priority" || name === "score" ? Number(value) : value,
      };
    });
  };
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "100%" : "35%",
          minHeight: isSmallScreen ? "100vh" : "50vh",
          maxHeight: isSmallScreen ? "100%" : "90vh",
          backgroundColor: "white",
          borderRadius: isSmallScreen ? "0px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            gap: "1rem",
            width: { xs: "100%" },
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
                Edit Option
              </CustomTypography>
            </Box>
            <Box>
              <Close
                onClick={handleModalClose}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: { xs: "100%" },
              minHeight: "200px",
              backgroundColor: "white",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Option Label"
                variant="outlined"
                name="label"
                value={editOption.label}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Option Title"
                variant="outlined"
                name="title"
                value={editOption.title}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextareaAutosize
                placeholder="Type Description here...."
                minRows={2}
                style={{ width: "100%", padding: "0.5rem" }}
                name="description"
                value={editOption.description}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Selected Option Name"
                variant="outlined"
                name="selectedOptionName"
                value={editOption.selectedOptionName}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            {(option?.condition?.conditionType === "select-single-increment" ||
              option?.condition?.conditionType ===
                "select-single-decrement") && (
              <Box sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  label="Not Selected Option Name"
                  variant="outlined"
                  name="notSelectedOptionName"
                  value={editOption.notSelectedOptionName}
                  onChange={handleInputChange}
                  size="small"
                  sx={{ width: "100%" }}
                />
              </Box>
            )}

            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Score (1-100)"
                variant="outlined"
                name="score"
                value={editOption.score || ""}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
                type="number"
                inputProps={{
                  step: "0.1",
                  min: "1",
                  max: "100",
                }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select score type
                </InputLabel>
                <Select
                  name="scoreType"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editOption.scoreType}
                  label="select category state"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"per"}>percent(%)</MenuItem>{" "}
                  <MenuItem value={"mul"}>multiplier(*)</MenuItem>
                  <MenuItem value={"ruppee"}>ruppee(₹)</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="increment-decrement-label">Action</InputLabel>
                <Select
                  name="action"
                  labelId="increment-decrement-label"
                  id="increment-decrement-select"
                  value={editOption.action}
                  label="Action"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"increment"}>Increment</MenuItem>
                  <MenuItem value={"decrement"}>Decrement</MenuItem>
                  <MenuItem value={"decrement-not-selected"}>
                    Decrement Not Selected
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select condition
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={editOption.condition}
                  label="select category state"
                  size="small"
                  name="condition"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  {conditions?.map((condition) => (
                    <MenuItem key={condition.id} value={condition.id}>
                      {condition.title} - {condition.step.stepName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button
                type="submit"
                variant="outlined"
                onClick={handleEditOption}
              >
                Edit Option
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditSellingOption;
