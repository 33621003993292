import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ProductVariants from "./ProductVariants";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useLocation } from "react-router-dom";

const ProductPart = ({ product }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const location = useLocation();
  const variant = new URLSearchParams(location.search).get("variantId");
  const productName = new URLSearchParams(location.search).get("productName");
  const selectedVariant =
    product.variants.find((v) => v.id === variant) || null;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          width: "100%",
          height: "100%",
          paddingTop: isSmallScreen ? "0.5rem" : "0",
          justifyContent: isSmallScreen ? "flex-start" : "center",
        }}
      >
        <Box>
          <CustomTypography
            variant={"h6"}
            sx={{
              fontSize: {
                xs: "1.125rem",
                laptop: "1.75rem",
              },
              color: "#214C7B",
              fontWeight: 700,
            }}
          >
            {productName === null ? product.productName : productName}
          </CustomTypography>
        </Box>
        {!isSmallScreen && <ProductVariants product={product} />}
      </Box>
    </>
  );
};

export default ProductPart;
