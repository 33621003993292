import { atom } from "recoil";

export const selectedAssessmentState = atom({
  key: "selectedAssessmentState",
  default: [],
});

export const currentSellingProductId = atom({
  key: "currentSellingProductId",
  default: "",
});
