import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import SellingFormContainer from "./sellingFormContainer/SellingFormContainer";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ASSESSMENT_BY_ID } from "../../../../graphql/Query";
import transformStepToRecoilState from "../../../../utils/transformStepToRecoilState";
import { useRecoilState } from "recoil";
import {
  currentSellingProductId,
  selectedAssessmentState,
} from "../../../../store/atoms/selling/sellingForms/sellingForms";

const SellingForm = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pId = params.get("pId");
  const cId = params.get("cId");
  const stepNo = params.get("stepNo");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  const [currentStep, setCurrentStep] = useState({});

  const { data, loading, error } = useQuery(QUERY_GET_ASSESSMENT_BY_ID, {
    variables: {
      categoryId: cId,
      stepNumber: parseInt(stepNo),
    },
  });

  useEffect(() => {
    if (data) {
      const step = data.getAssessmentByCategoryId;
      setCurrentStep(step);
    }
  }, [pId, cId, stepNo, data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
    <ResponsiveContainer
      sx={{
        minHeight: "calc(100vh - 137px)",
        minWidth: "300px",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Box
        margin={isSmallScreen ? "1rem 1rem" : "3rem 0"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={isSmallScreen ? "1rem" : "2rem"}
        sx={{ zIndex: 2 }}
      >
        <Box>
          <SellingFormContainer step={currentStep} />
        </Box>
      </Box>
    </ResponsiveContainer>
  );
};

export default SellingForm;
