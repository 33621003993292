import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { KeyboardArrowRight } from "@mui/icons-material";
import { selectedAssessmentState } from "../../../../../store/atoms/selling/sellingForms/sellingForms";
import { useRecoilState } from "recoil";
import { useNavigate, useLocation } from "react-router-dom";
import transformStepToRecoilState from "../../../../../utils/transformStepToRecoilState";

const Step2All = ({ step }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCondition, setSelectedCondition] = useRecoilState(
    selectedAssessmentState
  );
  const [selectedIndices, setSelectedIndices] = useState([]);

  const productName = new URLSearchParams(location.search).get("productName");
  const productId = new URLSearchParams(location.search).get("pId");
  const productPrice = new URLSearchParams(location.search).get("productPrice");
  const imageURL = new URLSearchParams(location.search).get("imageUrl");
  const cId = new URLSearchParams(location.search).get("cId");
  const cn = new URLSearchParams(location.search).get("cn");
  let stepNo = new URLSearchParams(location.search).get("stepNo");

  useEffect(() => {
    if (step && selectedCondition.length > 0) {
      const transformedStep = transformStepToRecoilState(step);
      setSelectedCondition((prevState) => {
        const stepExists = prevState.some(
          (existingStep) => existingStep.id === transformedStep.id
        );

        if (stepExists) {
          return prevState;
        } else {
          return [...prevState, transformedStep];
        }
      });
    }
  }, [step]);

  useEffect(() => {
    if (
      selectedCondition.length === 0 &&
      selectedCondition?.length < step?.stepNumber
    ) {
      navigate(
        `/sell/calculator/page?pId=${productId}&productName=${productName}&productPrice=${productPrice}&imageUrl=${imageURL}&cId=${cId}&cn=${cn}&stepNo=${1}`
      );
    }
  }, [selectedCondition, step?.stepNumber, navigate, cId]);

  const theme = useTheme();

  const isTabletScreen = useMediaQuery(
    theme.breakpoints.between("laptop", "desktop")
  );
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.between("xs", "tablet")
  );
  const isTabletMediumScreen = useMediaQuery(
    theme.breakpoints.between("tablet", "laptop")
  );

  useEffect(() => {
    const step2Data = selectedCondition?.find((s) => s?.id === step?.id);
    if (step2Data) {
      const selectedIndexes = step2Data.conditions
        .filter((condition) => condition.options[0]?.isSelected)
        .map((condition) => condition.id);

      setSelectedIndices(selectedIndexes);
    }
  }, [selectedCondition, step]);

  const handleSelect = (index) => {
    setSelectedIndices((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  const updateConditionOptions = (condition) => {
    if (selectedIndices.includes(condition.id)) {
      return {
        ...condition,
        options: condition.options.map((option, index) =>
          index === 0 ? { ...option, isSelected: true } : option
        ),
      };
    }
    return condition;
  };

  const updateStepConditions = (stepState) => {
    if (stepState.id === step.id) {
      return {
        ...stepState,
        conditions: stepState.conditions.map(updateConditionOptions),
      };
    }
    return stepState;
  };

  const handleNext = () => {
    setSelectedCondition((prevState) => prevState.map(updateStepConditions));
    navigate(
      `/sell/calculator/page?pId=${productId}&productName=${productName}&productPrice=${productPrice}&imageUrl=${imageURL}&cId=${cId}&cn=${cn}&stepNo=${++stepNo}`
    );
  };

  if (selectedCondition && selectedCondition.length < step.stepNumber) {
    return <>Loading....</>;
  }

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box>
        <Grid container spacing={{ xs: 1, laptop: 2 }}>
          {step.conditions.map((condition, index) => (
            <Grid
              key={index}
              item
              xs={4}
              tabletMedium={3}
              laptop={3}
              desktop={2.4}
            >
              <Box
                onClick={() => handleSelect(condition.id)}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={"1rem"}
                sx={{
                  height: "100%",
                  width: "100%",
                  padding: { xs: "0.5rem", laptop: "1rem" },
                  backgroundColor: selectedIndices.includes(condition.id)
                    ? "#D9E8FC"
                    : "#F4F6F9",
                  border: selectedIndices.includes(condition.id)
                    ? "2px solid #4285F4"
                    : "1px solid transparent",
                  backgroundImage: selectedIndices.includes(condition.id)
                    ? "none"
                    : "linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%)",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: isMobileScreen
                      ? "80px"
                      : isTabletMediumScreen
                      ? "130px"
                      : isTabletScreen
                      ? "150px"
                      : "200px",
                  }}
                >
                  <img
                    src={`https://d3473cyq46ezul.cloudfront.net/${condition.image}`}
                    alt={condition.title}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>

                <Box
                  sx={{
                    borderRadius: "20px",
                    border: "1px solid #214C7B",
                    background: "#FFF",
                    width: "100%",
                    padding: "0.25rem",
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    variant={"subtitle2"}
                    sx={{
                      color: "#494949",
                      fontSize: {
                        xs: "0.6rem",
                        tabletMedium: "0.8rem",
                        laptop: "0.95rem",
                        desktop: "1rem",
                      },
                      fontWeight: "700",
                      textAlign: "center",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                    }}
                  >
                    {condition.title}
                  </CustomTypography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="outlined"
          onClick={handleNext}
          sx={{
            backgroundColor: "#214C7B",
            borderRadius: "10px",
            color: "white",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#214C7B",
            },
          }}
        >
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Next <KeyboardArrowRight />
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default Step2All;
