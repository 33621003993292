import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import SellingForm from "../components/sellingForms/SellingForm";

const SellingFormPage = () => {
  return (
    <>
      <SellingForm />
    </>
  );
};

export default SellingFormPage;
