import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SeriesItem = ({ series, isActive, onClick }) => {
  return (
    <Box
      sx={{
        padding: "1rem",
        backgroundColor: isActive ? "#D0E4F7" : "transparent", // Active series style
        cursor: "pointer",
        borderRadius: "0.5rem",
        "&:hover": {
          backgroundColor: "#F5F7FA", // Hover effect
        },
      }}
      onClick={onClick} // Handle click
    >
      <CustomTypography
        variant={"subtitle2"}
        sx={{ fontWeight: isActive ? "bold" : "normal" }} // Bold text for active series
      >
        {series.seriesName}
      </CustomTypography>
    </Box>
  );
};

export default SeriesItem;
