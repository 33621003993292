import React, { useState } from "react";

import {
  loadingStateAtom,
  productDetailsAtom,
} from "../../../../store/atoms/productDetails/productAtom";
import { useRecoilValue } from "recoil";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import ImagesShowModal from "./ImagesShowModal";
import "../../css/style.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Box, Skeleton } from "@mui/material";

const ProductImageSlider = () => {
  const [openModal, setOpenModal] = useState(false);
  const loading = useRecoilValue(loadingStateAtom);
  const product = useRecoilValue(productDetailsAtom);
  const handleImageClick = () => {
    setOpenModal(true);
  };

  return (
    <>
      {loading || !product || !product.image ? (
        <Box
          sx={{
            width: "100%",
            height: "60vh",
          }}
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height="100%"
            sx={{ borderRadius: "1rem" }}
          />
        </Box>
      ) : (
        <div className="slider-container">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {product &&
              product.images &&
              product.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    onClick={handleImageClick}
                    src={`${`https://d3473cyq46ezul.cloudfront.net/`}${
                      image.lg
                    }`}
                    alt=""
                    style={{
                      width: "100%",
                      height: "60vh",
                      objectFit: "cover",
                      borderRadius: "1rem",
                    }}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
      {!loading && product.image && product && (
        <ImagesShowModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          images={product.images}
        />
      )}
    </>
  );
};

export default ProductImageSlider;
