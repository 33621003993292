import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import Banner_1 from "../../../../../Images/selling/test_banners/Banner_1.png";
import AllModelsBrands from "./AllModelsBrands";
import { useTheme } from "@emotion/react";

const AllModels = ({ models }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: isSmallScreen ? "1rem" : "2rem",
      }}
    >
      <Box
        sx={{
          borderRadius: "15px",
          height: "100%",
          width: "100%",
        }}
      >
        <img
          src={Banner_1}
          style={{
            width: "100%",
            height: "100%",
            aspectRatio: isSmallScreen ? 2.25 / 1 : 3 / 1,
            borderRadius: "1rem",
            objectFit: "fill",
          }}
        />
      </Box>
      <AllModelsBrands models={models} />
    </Box>
  );
};

export default AllModels;
