import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { Close } from "@mui/icons-material";
import { EDIT_SELLING_STEP } from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import { QUERY_GET_ALL_SELLING_STEPS } from "../../../../graphql/Query";

const SellingStepModal = ({ step, modalOpen, setModalOpen, assessments }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [editSellingStep, { loading }] = useMutation(EDIT_SELLING_STEP, {
    refetchQueries: [QUERY_GET_ALL_SELLING_STEPS],
  });

  const [editStep, setEditStep] = useState({
    stepName: "",
    finalStepTitle: "",
    title: "",
    subtitle: "",
    stepNumber: 0,
    description: "",
    priority: 0,
    assessment: "",
  });

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditStep((prevState) => ({
      ...prevState,
      [name]:
        name === "stepNumber" || name === "priority" ? Number(value) : value,
    }));
  };

  const handleEditStep = async () => {
    try {
      const editFinalStep = {
        stepNumber: editStep.stepNumber,
        stepName: editStep.stepName,
        finalStepTitle: editStep.finalStepTitle,
        title: editStep.title,
        subtitle: editStep.subtitle,
        description: editStep.description,
        priority: editStep.priority,
        assessment: editStep.assessment,
      };

      const { data } = await editSellingStep({
        variables: {
          editSellingStep: editFinalStep,
          editStepId: step.id,
        },
      });

      swal({
        title: "Success",
        text: data.editSellingStep.message,
        icon: "success",
      }).then(() => {
        handleModalClose();
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setEditStep({
      stepName: step?.stepName,
      finalStepTitle: step?.finalStepTitle,
      title: step?.title,
      subtitle: step?.subtitle,
      stepNumber: step?.stepNumber,
      description: step?.description,
      priority: step?.priority,
      assessment: step?.assessment?.id,
    });
  }, [step]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "100%" : "35%",
          minHeight: isSmallScreen ? "100vh" : "50vh",
          maxHeight: isSmallScreen ? "100%" : "90vh",
          backgroundColor: "white",
          borderRadius: isSmallScreen ? "0px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            width: { laptop: "100%", xs: "100%" },
            gap: "1rem",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
                Edit Selling Step
              </CustomTypography>
            </Box>
            <Box>
              <Close
                onClick={handleModalClose}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
              minHeight: "200px",
              backgroundColor: "white",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Name"
                variant="outlined"
                name="stepName"
                value={editStep?.stepName}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Title"
                variant="outlined"
                name="title"
                value={editStep?.title}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Subtitle"
                variant="outlined"
                name="subtitle"
                value={editStep?.subtitle}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Priority"
                variant="outlined"
                type={"number"}
                name="priority"
                value={editStep?.priority === 0 ? "" : editStep?.priority}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Step Number"
                type={"number"}
                variant="outlined"
                name="stepNumber"
                value={editStep?.stepNumber === 0 ? "" : editStep?.stepNumber}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Final Step Title"
                type={"text"}
                variant="outlined"
                name="finalStepTitle"
                value={editStep?.finalStepTitle}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <TextareaAutosize
                placeholder="Type Description here...."
                minRows={2}
                style={{ width: "100%", padding: "0.5rem" }}
                value={editStep?.description}
                name={"description"}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select assessment
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="assessment"
                  value={editStep?.assessment}
                  label="select category state"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  {assessments?.map((assessment) => (
                    <MenuItem key={assessment?.id} value={assessment?.id}>
                      {assessment?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button type="submit" variant="outlined" onClick={handleEditStep}>
                Edit Step
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SellingStepModal;
