import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useNavigate } from "react-router-dom";

const ModelBrand = ({ product }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("tabletMedium"));
  const handleClick = () => {
    navigate(`/sell/product/${product.id}`);
  };

  return (
    <Box
      sx={{
        borderRadius: "15px",
        backgroundColor: "#E0E9F1",
        padding: "0.25rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          width: "100%",
          height: isSmallScreen ? "80px" : "150px",
        }}
      >
        <img
          src={`https://d3473cyq46ezul.cloudfront.net/${product.image}`}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Box>
      <Box
        sx={{
          padding: isSmallScreen
            ? "0 0.5rem 0.5rem 0.5rem"
            : "0 1rem 1rem 1rem",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            fontWeight: "600",
            fontSize: { xs: "0.65rem", laptop: "0.75rem" },
            textAlign: "center",
          }}
        >
          {product.productName}
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default ModelBrand;
