import { useQuery } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { QUERY_GET_SELLINGPRODUCTS_BY_BRAND } from "../../../../../graphql/Query";
import ModelBrand from "./ModelBrand";
import { useRecoilState, useRecoilValue } from "recoil";
import { brandCategoryState } from "../../../../../store/atoms/selling/brands/brandsAtom";
import { useLocation, useParams } from "react-router-dom";
import { modelSeriesAtom } from "../../../../../store/atoms/selling/modelProducts/modelProductAtom";

const AllModelsBrands = ({ models }) => {
  return (
    <Box>
      <Grid container spacing={{ xs: 1, laptop: 3 }}>
        {models.map((product) => (
          <Grid
            item
            xs={4}
            laptop={4}
            tabletLarge={3}
            desktop={2.4}
            key={product.id}
          >
            <ModelBrand product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AllModelsBrands;
