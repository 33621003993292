import {
  Modal,
  Box,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import CustomTypography from "../../muicomponents/shared/CustomTypography";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_All_CATEGORY_TYPE } from "../../graphql/Query";

const SellingModal = ({ modalOpen, setModalOpen }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const { data, loading, error } = useQuery(QUERY_GET_All_CATEGORY_TYPE, {
    variables: {
      categoryType: "sell",
    },
  });

  const handleNavigate = (obj) => {
    navigate(`/sell/${obj.id}`);
    handleClose();
  };

  const handleClose = () => {
    setModalOpen({ ...modalOpen, sell: false });
  };
  return (
    <Modal
      disableScrollLock={true}
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          outline: "none",
          border: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          maxWidth: {
            extraLarge: "600px",
            laptop: "60%",
            desktop: "50%",
            xs: "100%",
          },
          width: { xs: "95%", tabletMedium: "70%", laptop: "100%" },
          transform: "translate(-50%, -50%)",
          minHeight: "300px",
          backgroundColor: "#A4D0FF",
          padding: { xs: "1rem", laptop: "1.5rem" },
          borderRadius: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1.5px solid #A1A1A1",
              mb: 3,
            }}
          >
            <CustomTypography
              variant="h5"
              sx={{
                fontSize: { xs: "1rem", laptop: "1.25rem", desktop: "1.5rem" },
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Choose Your Preference
            </CustomTypography>
            <IconButton
              onClick={handleClose}
              sx={{
                color: "#000",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            container
            sx={{
              alignItems: "center",
              position: "relative",
            }}
          >
            {data?.getAllCategoryByType.map((obj, index) => {
              const isLastRow = index >= data?.getAllCategoryByType.length - 3;
              const isLastColumn = (index + 1) % 3 === 0;

              return (
                <Grid
                  item
                  xs={4}
                  key={index}
                  sx={{
                    borderRight: isLastColumn ? "none" : "2px solid #A1A1A1",
                    borderBottom: isLastRow ? "none" : "2px solid #A1A1A1",
                    py: 2,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleNavigate(obj)}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <Box>
                      <img
                        src={`https://d3473cyq46ezul.cloudfront.net/${obj.image}`}
                        style={{
                          width: "100%",
                          height: isMobileScreen ? "70px" : "80px",
                          objectFit: "contain",
                        }}
                        alt={obj.image}
                      />
                    </Box>
                    <CustomTypography
                      sx={{
                        mt: 1,
                        fontSize: "0.75rem",
                        textAlign: "center",
                        color: "#000",
                      }}
                    >
                      {obj?.name}
                    </CustomTypography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default SellingModal;
