import React from "react";
import DiscountFunction from "../utils/DiscountFunction";
import { Card, Container, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const TrendingGadCard = ({ product, isBuy }) => {
  const navigate = useNavigate();
  const handleProductClick = (product) => {
    if (isBuy) {
      navigate(`/buying-product-details/${product.id}`);
    } else if (product.productCategory === "Desktop/Imac") {
      navigate(`/buying-products/Desktop-Imac`, {
        state: {
          product: product,
        },
      });
    } else {
      navigate(`/buying-products/${product.productCategory}`, {
        state: {
          product: product,
        },
      });
    }
  };
  return (
    <Card className="brand-card" onClick={() => handleProductClick(product)}>
      <div className="overlay"></div>

      <div className="rounded-top-left">
        {isBuy ? (
          <>
            <Typography
              sx={{
                lineHeight: "0.9",
                fontSize: { xs: "0.7rem", sm: "1rem" },
              }}
            >
              {product.condition === "Partially-Fair"
                ? "P-Fair"
                : product.condition}{" "}
              condition
            </Typography>
          </>
        ) : (
          "SALE"
        )}
      </div>
      <div className="rounded-top-right">
        <p className="discount">
          {DiscountFunction(product.productPrice, product.mrp)}%
        </p>
        <p className="discount-off">OFF</p>
      </div>
      <Card.Img
        variant="top"
        className="card-img"
        src={`https://d3473cyq46ezul.cloudfront.net/${product?.images[0].md}`}
      />
      <Card.Body>
        <Card.Title className="trending-gad-title">
          {product.productName}
        </Card.Title>
        <div className="parent-container">
          <div className="price-section ">
            <p className="price">₹{product.productPrice}</p>
          </div>
          <div className="mrp-section">
            <p className="mrp">₹{product.mrp}</p>
          </div>
        </div>
        {isBuy ? (
          <div className="view-all">View</div>
        ) : (
          <div className="view-all">View All</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default TrendingGadCard;
