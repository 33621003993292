import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { MUTATION_EDIT_SELLING_CONDITION } from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import { QUERY_GET_ALL_CONDITIONS } from "../../../../graphql/Query";

const EditSellingCondition = ({
  condition,
  modalOpen,
  setModalOpen,
  steps,
  setEditParentCondition,
}) => {
  const [editSellingCondition, { loading }] = useMutation(
    MUTATION_EDIT_SELLING_CONDITION,
    {
      refetchQueries: [QUERY_GET_ALL_CONDITIONS],
    }
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [editCondition, setEditCondition] = useState({
    conditionType: "",
    title: "",
    subtitle: "",
    description: "",
    priority: 0,
    step: "",
    finalConditionName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCondition((prevState) => {
      return {
        ...prevState,
        [name]: name === "priority" ? Number(value) : value,
      };
    });
  };

  const handleEditCondition = async () => {
    try {
      const editFinalCondition = {
        conditionType: editCondition.conditionType,
        title: editCondition.title,
        subtitle: editCondition.subtitle,
        description: editCondition.description,
        priority: editCondition.priority,
        step: editCondition.step,
        finalConditionName: editCondition.finalConditionName,
      };

      const { data } = await editSellingCondition({
        variables: {
          editSellingCondition: editFinalCondition,
          editConditionId: condition.id,
        },
      });

      swal({
        title: "Success",
        text: data.editSellingCondition.message,
        icon: "success",
      }).then(() => {
        handleModalClose();
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (condition) {
      setEditCondition({
        finalConditionName: condition?.finalConditionName || "",
        conditionType: condition?.conditionType || "",
        title: condition?.title || "",
        subtitle: condition?.subtitle || "",
        description: condition?.description || "",
        priority: condition?.priority || 0,
        step: condition?.step?.id || "",
      });
    }
  }, [condition]);

  const handleModalClose = () => {
    setModalOpen(false);
    setEditParentCondition(null);
  };
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "100%" : "35%",
          minHeight: isSmallScreen ? "100vh" : "50vh",
          maxHeight: isSmallScreen ? "100%" : "90vh",
          backgroundColor: "white",
          borderRadius: isSmallScreen ? "0px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem",
            borderRadius: "0.5rem",
            width: { xs: "100%" },
            gap: "1rem",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
                Edit Condition
              </CustomTypography>
            </Box>
            <Box>
              <Close
                onClick={handleModalClose}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              width: "100%",
              minHeight: "200px",
              backgroundColor: "white",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Title"
                variant="outlined"
                name="title"
                value={editCondition.title}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextareaAutosize
                placeholder="Type Description here...."
                minRows={2}
                style={{ width: "100%", padding: "0.5rem" }}
                name="description"
                value={editCondition.description}
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Subtitle"
                variant="outlined"
                name="subtitle"
                type="text"
                value={editCondition.subtitle}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Final Name"
                variant="outlined"
                name="finalConditionName"
                type="text"
                value={editCondition.finalConditionName}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Condition Priority"
                variant="outlined"
                name="priority"
                type="number"
                value={editCondition.priority}
                onChange={handleInputChange}
                size="small"
                sx={{ width: "100%" }}
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Condition Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="conditionType"
                  value={editCondition.conditionType}
                  label="select"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"mcq"}>multiple choice</MenuItem>
                  <MenuItem value={"yes/no"}>yes/no</MenuItem>
                  <MenuItem value={"select-single-decrement"}>
                    select single decrement
                  </MenuItem>
                  <MenuItem value={"select-single-increment"}>
                    select single increment
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  select step{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="step"
                  value={editCondition.step}
                  label="select"
                  size="small"
                  onChange={handleInputChange}
                  sx={{ width: "100%" }}
                >
                  {steps?.map((step) => (
                    <MenuItem key={step.id} value={step.id}>
                      {step.stepName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button
                type="submit"
                variant="outlined"
                onClick={handleEditCondition}
              >
                Edit Condition
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditSellingCondition;
