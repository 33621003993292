import { Box, Button, Grid, Step } from "@mui/material";
import React from "react";
import SellingCondition from "./SellingCondition";
import { KeyboardArrowRight } from "@mui/icons-material";
import StepTitle from "../../shared/StepTitle";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import Step1All from "../sellingSteps/Step1All";
import Step2All from "../sellingSteps/Step2All";
import Step3All from "../sellingSteps/Step3All";
import Step4All from "../sellingSteps/Step4All";
import Step3Laptop from "../sellingSteps/Step3Laptop";

const SellingFormContainer = ({ step }) => {
  const renderStepComponent = () => {
    switch (step.stepNumber) {
      case 1:
        return <Step1All step={step} />;
      case 2:
        return <Step2All step={step} />;
      case 3:
        return <Step3All step={step} />;
      case 4:
        return <Step4All step={step} />;
      default:
        return <div>Unknown Step</div>;
    }
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box>
        <StepTitle
          title={`Steps - ${step.stepNumber}/${step.totalSteps}`}
          subtitle={step.title}
          subtitle2={step.subtitle}
        />
      </Box>
      <Box>{renderStepComponent()}</Box>
    </Box>
  );
};

export default SellingFormContainer;
