import { Box } from "@mui/material";
import React from "react";

const ProductImage = ({ imageKey }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "150px",
            tabletMedium: "300px",
            laptop: "300px",
            desktop: "500px",
          },
          height: {
            xs: "150px",
            tabletMedium: "300px",
            laptop: "300px",
            desktop: "500px",
          },
        }}
      >
        <img
          src={`https://d3473cyq46ezul.cloudfront.net/${imageKey}`}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default ProductImage;
